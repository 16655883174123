const slugMixin = {
  methods: {},

  computed:
  {
    slug()
    {
      return this.$route.params.slug
    }
  },

  watch:
  {
    slug(newSlug, oldSlug)
    {
      if( oldSlug && newSlug !== oldSlug && this.load )
        this.load()
    }
  }
}

export default slugMixin