import Api from './'

const store = {

	/**
	 * Get a payload on Zwan Store.
	 */
	payload(data = {})
	{
		let url = Api.getUrl('store/state')

		return Api.client().post(url, {...data})
	}

}

export default store