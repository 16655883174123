import Api from '@/api'
import axios from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'

import utility from '@/mixins/utility'

import User from '@/models/user'

// modules.
import ecommerce from './ecommerce'
import ui from './ui'

Vue.use(Vuex)

export default new Vuex.Store({

  modules: {
    ecommerce,
    ui
  },

  state: {
    favourites: [],
    user: null,
    packageVersion: process.env.PACKAGE_VERSION || '0'
  },

  mutations: {

    setUserData (state, userData) {
      state.user = new User(userData.user)
      localStorage.setItem('user', JSON.stringify(userData))

      axios.defaults.headers.common.Authorization = `Bearer ${userData.token}`
    },

    clearUserData () {
      localStorage.removeItem('user')
      localStorage.removeItem('favourites')
      location.reload()
    },

    setUserFavourites(state, userFavourites) {
      let shows = utility.methods.mapModels(userFavourites)
      state.favourites = shows
      localStorage.setItem('favourites', JSON.stringify(userFavourites))
    },

  },

  actions: {
    login ({ commit, dispatch }, credentials) {
      return Api.login(credentials)
        .then(({ data }) => {
          commit('setUserData', data)
          dispatch('loadFavourites')
        })
    },

    logout ({ commit }) {
      commit('clearUserData')
    },

    loadFavourites({ commit }) {
      if( this.getters.isLogged )
        Api.favourites.list()
           .then(({ data })=>{
            commit('setUserFavourites', data.data)
           })
           .catch((error)=>{
            console.log(error.response)
           })
    },

    refreshUserData({ commit })
    {
      return Api.auth.refresh()
        .then(({ data }) => {
          commit('setUserData', data)
        })
        .catch((err)=>{
          console.log(err)
        })
    },

    toggleFav({ dispatch }, show)
    {
      let showId = show.id

      return Api.favourites.toggle(showId)
                    .then(({ data })=>{

                      // Google Analytics.
                      Vue.$gtag.event('wishlist', {
                        'event_category': 'wishlist',
                        'event_label': data.new_status ? 'added' : 'removed',
                        'value': show.title
                      })
                      
                      // refresh favs.
                      dispatch('loadFavourites')
                    })
    },

  },

  getters : {
    isLogged: state => !!state.user,
    user: (state)=>{
      return state.user ? state.user : null
    },
    appVersion: (state) => {
      return state.packageVersion
    },
    favourites: (state)=>{
      return state.favourites
    },
  }
})