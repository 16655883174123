import Api from './'

const shows = {

	/**
	 * Get a list of collections.
	 */
	list(params={})
	{
		let url = Api.getUrl(`collections`)

		return Api.client().get(url, { params })
	},

	/**
	 * Get one collection details.
	 */
	one(slug, params={})
	{
		let url = Api.getUrl(`collection/${slug}`)

		return Api.client().get(url, { params })
	},

	/**
	 * Get a random collection.
	 */
	random(params={})
	{
		let url = Api.getUrl(`collection/random`)

		return Api.client().get(url, { params })
	},

}

export default shows