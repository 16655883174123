import utility from '@/mixins/utility'

class Serie {

  #data = {}

  #episodes = []

  constructor(serie={})
  {  
    this.#data = serie

    this.#episodes = utility.methods.mapModels(serie.episodes)
  }

  /**
   * Property getters.
   */
  
  get id()
  {
    return this.#data.id
  }

  get slug()
  {
    return this.#data.slug
  }

  get name()
  {
    return this.#data.name
  }

  get showId()
  {
    return this.#data.show_id
  }

  get showSlug()
  {
    return this.#data.show_slug
  }

  get episodes()
  {
    return this.#episodes
  }

  /**
   * Computed properties.
   */

  get isEmpty()
  {
    return this.episodes.length == 0
  }

  get isNotEmpty()
  {
    return this.episodes.length != 0
  }

}

export default Serie