import utility from '@/mixins/utility'

import _ from 'lodash'
import moment from 'moment'

class User {

  #data = {}

  #paymentMethods = []

  #subscriptions = []

  constructor(collection={})
  {
    this.#data = collection

    this.#paymentMethods = utility.methods.mapModels(collection.payment_methods, 'paymentMethod')

    this.#subscriptions = utility.methods.mapModels(collection.subscriptions, 'subscription')
  }

  /**
   * Property getters.
   */
  
  get active()
  {
    return this.#data.active == true
  }

  get completedAt()
  {
    return this.#data.completed_at
  }
  
  get email()
  {
    return this.#data.email
  }

  get emailVerifiedAt()
  {
    let value = this.#data.email_verified_at

    return value ? moment(value) : null
  }

  get id()
  {
    return this.#data.id
  }

  get lastname()
  {
    return this.#data.lastname
  }

  get marketingAt()
  {
    let value = this.#data.marketing_at

    return value ? moment(value) : null
  }

  get name()
  {
    return this.#data.name
  }

  get paymentMethods()
  {
    return this.#paymentMethods
  }

  get subscriptions()
  {
    return this.#subscriptions
  }

  get tocAt()
  {
    let value = this.#data.toc_at

    return value ? moment(value) : null
  }

  get token()
  {
    return this.#data.token
  }

  /**
   * Computed properties.
   */
  
  get fullname()
  {
    return [ this.name, this.lastname ].join(' ')
  }

  get emailIsVerified()
  {
    return this.emailVerifiedAt ? true : false
  }

  get hasFullname()
  {
    return this.name && this.lastname
  }

  get hasMarketing()
  {
    return this.marketingAt ? true : false
  }

  get hasPaymentMethods()
  {
    return this.paymentMethods.length > 0
  }

  get hasPassword()
  {
    return this.completedAt ? true : false
  }

  get hasSubscription()
  {
    return this.subscriptions.length > 0
  }

  get hasToc()
  {
    return this.tocAt
  }

  /**
   * Methods.
   */
  
  checkPlans(plans=[])
  {
    if( !this.hasSubscription )
      return false

    let subscriptions = _.map(this.subscriptions, 'plan.id'),
        allowedPlans = _.map(plans, 'id'),
        hasPlan = _.intersection(subscriptions, allowedPlans).length > 0

    return hasPlan
  }

}

export default User