const liveTyping = {
  
    created () {},

    mounted () {},

    data: () => ({
        liveTyping: {
            isTyping: false,
            timeout: 1000,
            timer: null
        }
    }),
  
    computed: {
        liveTypingIcon()
        {
            return this.liveTyping.isTyping ? 'mdi-loading mdi-spin' : ''
        }
    },

    methods: {
        liveType(resolve)
        {
            if (this.liveTyping.timer)
              clearTimeout(this.liveTyping.timer)

            this.liveTyping.isTyping = true
            
            this.liveTyping.timer = setTimeout( () => {

                this.liveTyping.isTyping = false

                resolve()

            }, this.liveTyping.timeout)
        }
    }
  
}

export default liveTyping