<template>
  <div>

    <div v-if="show">

      <VideoPlayer :trailer="true" :show="show"/>

    </div>

    <v-container class="py-10" v-if="showError">
      <v-row class="error">
        <v-col>
          <v-btn fab @click="goTo('home')" 
                     :elevation="0"
                     class="mr-5 red darken-1">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          Ops! C'&egrave; stato un errore con il caricamento del video.
        </v-col>
      </v-row>
    </v-container>

  </div>
</template>

<style lang="scss" scoped>  
  
</style>

<script>
import utility from '@/mixins/utility'

import API from '@/api'
import VideoPlayer from '@/components/video/Player2'

import { mapMutations } from 'vuex'

export default {
  name: 'Trailer',
  
  mixins: [utility],
  
  components: {
    VideoPlayer
  },
  
  data: () => ({
    mainShow: null,
    show: null,
    showError: false
  }),

  computed:
  {

  },

  mounted()
  {
    this.load()
  },

  methods:
  {
    ...mapMutations({
      setLoading: 'ui/setLoading'
    }),

    load()
    {
      var showSlug = this.$route.params.slug

      this.setLoading(true)

      API.shows.one(showSlug)
               .then((res)=>{
                
                this.show = this.mapModel(res.data.data)

                this.setLoading(false)

               })
               .catch(()=>{

                this.setLoading(false)

                this.showError = true

               })
    },

    loadMainShow()
    {
      this.mainShow = null

      if(this.show && this.show.isEpisode && this.show.serie)
        API.shows.one(this.show.serie.showSlug)
               .then((res)=>{
                
                this.mainShow = this.mapModel(res.data.data)

               })
    }
  },

  watch:
  {
    show()
    {
      this.loadMainShow()
    }
  }

}
</script>
