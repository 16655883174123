<template>

  <div class="show-card"
       @click="goToSmart(show)"
       v-focus>
                       
     <div class="gen-carousel-movies-style-2 movie-grid">

        <div class="gen-movie-contain">
           <div class="gen-movie-img">
              <img :src="img" 
                   :alt="show.title"
                   @load="imageLoaded=true"
                   v-show="imageLoaded"/>
              <v-skeleton-loader
                type="image"
                v-show="!imageLoaded"
                :height="layoutIsPoster ? 356 : 143"
              ></v-skeleton-loader>
              <!-- tagline -->
              <span class="tagline" 
                    v-if="show.tagline && !isMobile">
                  {{ show.tagline }}
              </span>
              <div class="gen-movie-add">
                <!-- <WatchlistButton :show="show"/> -->
              </div>
              <div class="gen-movie-action"
                   v-if="playIcon">
                <v-btn color="primary" fab
                       @click="goToSmart(show)">
                  <v-icon>mdi-play</v-icon>
                </v-btn>
              </div>
           </div>

           <div class="gen-info-contain"
                v-if="details">
              <div class="gen-movie-info">
                 <h3 :class="{ 'wrap-title':wrapTitle }">
                  <a @click="goToSmart(show)">
                    {{ show.title }}
                  </a>
                 </h3>
              </div>
              <div class="gen-movie-meta-holder">
                 <ul>
                    <li v-if="show.duration">
                      {{ show.duration }}
                    </li>
                    <li>
                      <span v-for="(genre,i) in show.genres" :key="i">
                        <a @click="goTo({ name:'genre', params:{slug:genre.slug} })">
                          {{ genre.title }}
                        </a>
                        <span v-if="(i+1) < show.genres.length">, </span>
                      </span>
                    </li>
                 </ul>
              </div>
           </div>

        </div>
     </div>
  
  </div>
  <!-- #post-## -->
</template>

<style lang="scss" scoped>

  .show-card {

    &:hover,
    &.focused {
      .gen-carousel-movies-style-2 {
        .gen-movie-contain {
          .gen-movie-img {
            box-shadow: rgb(0 0 0 / 80%) 0px 40px 58px -16px, rgb(0 0 0 / 72%) 0px 30px 22px -10px, 0 0 20px 0 var(--primary);
            transform: scale(1.05, 1.05) translateZ(0px) translate3d(0px, 0px, 0px);
            transition-duration: 300ms;
            transition-property: transform, box-shadow;
            transition-timing-function: ease-out;
          }
        }
      }
    }

    .gen-carousel-movies-style-2 {

      .gen-movie-contain {

        &:hover {
          .gen-movie-img {
            img {
              transform: none;
            }
          }      
        }
    
        .gen-movie-img {
          border-radius: 4px;
          box-shadow: rgb(0 0 0 / 69%) 0px 26px 30px -10px, rgb(0 0 0 / 73%) 0px 16px 10px -10px;
          transform: scale(1, 1) translateZ(0px);
          transition-duration: 300ms;
          transition-property: transform, box-shadow;
          transition-timing-function: ease-out;
          -webkit-font-smoothing: subpixel-antialiased;

          &:before {
            background: none;
          }

        }

        .gen-movie-info {
          h3 {
            &:not(.wrap-title) {
              overflow: visible;
              white-space: normal;
            }
          }
        }

        .tagline {
          font-family: "Roboto", sans-serif;
          position: absolute;
          left: 0;
          bottom: 0;
          max-width: 70%;
          text-transform: uppercase;
          letter-spacing: 4px;
          font-weight: 600;
          font-size: 13px;
          line-height: 20px;
          margin: 0 0 15px 0;
          //border-left: 2px solid #1f80e0;
          padding: 5px 5px 5px 15px;
          color: var(--white-color);
          background-color: var(--primary);
        }

      }

    }

  }
</style>

<script>
  import utility from '@/mixins/utility'

  import Show from '@/models/show'

  //import WatchlistButton from '@/components/watchlist/Button'

  export default {
    name: 'ShowCard',

    mixins: [utility],

    data: () => ({
      imageLoaded: false
    }),

    components: {
      //WatchlistButton
    },

    props: {
      details: {
        type: Boolean,
        default: false
      },
      layout: {
        type: String,
        default: 'cover'
      },
      playIcon: {
        type: Boolean,
        default: false
      },
      show: {
        type: Show,
        default: ()=>new Show
      },
      wrapTitle: {
        type: Boolean,
        default: false
      }
    },

    computed:
    {
      img()
      {
        if( this.layoutIsPoster )
          return this.show.poster

        return this.show.cover
      },

      layoutIsPoster()
      {
        return this.layout === 'poster'
      }
    }
  }
</script>
