<template>
  <div class="py-15">

    <SignupStoreComponent/>  
    
  </div>
</template>

<script>

import SignupStoreComponent from '@/components/signup/SignupStore'

export default {
  
  name: 'Signup',

  mixins: [],

  components: {
    SignupStoreComponent
  },

  data: () => ({

  }),

  computed: {

    

  },

  methods:
  {
    
  },

  watch:
  {
    
  },

  mounted()
  {

  }

}
</script>
