const Pagination = {
  
  data: () => ({
    pagination: {
      current_page: 0,
      last_page: 1
    }
  }),

  computed:
  {
    _paginationHasMore()
    {
      return this.pagination.current_page < this.pagination.last_page
    }
  },

  methods:
  {
    _getPage()
    {
      return this.pagination.current_page
    },
    _getNextPage()
    {
      return this._getPage() + 1
    },
    _updatePagination(meta)
    {
      this.pagination = meta
    }
  }
}

export default Pagination