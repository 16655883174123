<template>

    <v-row>
      <v-col>
        
        <v-text-field 
                outlined
                type="email"
                label="Indirizzo email"
                v-model="theEmail"
                :autofocus="true"
                class="transparent"
                @keyup.enter="sendVerificationCode"
                :disabled="isSending"/>

        <v-alert type="warning"
                 v-if="errorMessage"
                 v-html="$t(errorMessage)">
        </v-alert>
        
        <p class="login-submit text-center">
            <v-btn tile x-large color="primary"
                @click="sendVerificationCode"
                :disabled="!canSubmit"
                :loading="isSending">
              Ricevi un nuovo codice
            </v-btn>
        </p>
        
        <p class="text-center mt-5">
          <v-btn @click="goTo('signup')" text>
            Non hai un account? Registrati!
          </v-btn>
          <v-btn @click="goTo('login')" text>
            Hai gi&agrave; verificato la tua e-mail?
          </v-btn>
        </p>

      </v-col>
    </v-row>

</template>

<style lang="scss" scoped>
  
</style>

<script>
  import API from '@/api'

  import utility from '@/mixins/utility'

  import { mapMutations } from 'vuex'

  export default {
    name: 'SignupSendEmailVerification',

    mixins: [utility],

    data: () => ({
      isSending: false,
      theEmail: null,
      errorMessage: null
    }),

    computed:
    {
      canSubmit()
      {
          return ( this.theEmail )
      },
    },

    props:
    {
      email: {
        type: String
      }
    },

    methods:
    {
      ...mapMutations({
        growl: 'ui/growl'
      }),

      sendVerificationCode()
      {
          if( !this.canSubmit )
              return
          
          this.errorMessage = null

          this.isSending = true

          API.signup.sendVerificationCode(this.theEmail)
                     .then(()=>{
                      
                      //this.goTo('home')
                      
                      this.growl({
                        content: 'Abbiamo inviato il link alla tua casella di posta!',
                        color: 'success'
                      })

                      this.isSending = false

                     })
                     .catch(({ response })=>{
                      
                      this.growl({
                        content: this.$t(response.data.message),
                        color: 'error'
                      })

                      this.isSending = false

                     })
      }
    },

    watch:
    {
      email(email)
      {
        this.theEmail = email
      }
    },

    mounted()
    {
      if( this.email )
        this.theEmail = this.email
    }
  }
</script>
