class RemoteKeysHandler{

	static instance;

	installed = false;

	mixinRegistered = false;

	//Map 
	// 1o arg-> string (nome evento),
	// 2o arg-> array of methods
	eventsMap = new Map();
	
	globalListeners = []
	
	constructor(){
		
		if(typeof RemoteKeysHandler.instance === "object") return RemoteKeysHandler.instance
		
		RemoteKeysHandler.instance = this
		
		return this
		
	}
	
	install(Vue){

		if(this.installed) return
		
		this.installed = true;
		this.registerPlugin(Vue)
		// this.registerInputListener()
		
	}

	registerPlugin(Vue){

		Vue.prototype.$addRemoteKeyListener = (key, hook) => this.registerEventHook(key, hook)
		Vue.prototype.$deleteRemoteKeyListener = (key, hook) => this.deleteEventHook(key, hook)

		Vue.prototype.$addRemoteKeysListener = (hook) => this.registerGlobalEventHook(hook)
		Vue.prototype.$deleteRemoteKeysListener = (hook) => this.deleteGlobalEventHook(hook)
		
	}

	registerEventListener(){

		document.addEventListener('keydown-event', (event) => {

			const key = event.detail
			const keyHooks = this.eventsMap.get(key)


			for(const fn of this.globalListeners){
				if(typeof fn === "function") fn(event)
			}
			

			if(keyHooks && Array.isArray(keyHooks) && keyHooks.length > 0){
				
				keyHooks.forEach((hook) => {
					hook(event)
				})

			}


		})

	}

	//Registra event listener per ogni tasto del telecomando premuto
	registerGlobalEventHook(fn){
		this.globalListeners.push(fn)
	}

	//Elimina event listener generico di tutti i tasti
	deleteGlobalEventHook(fn){
		const index = this.globalListeners.indexOf(fn)
		this.globalListeners.splice(index, 1)
	}

	//Registra un hook personalizzato alla pressione di un tasto specifico del telecomando
	registerEventHook(key, hook){

		if(this.eventsMap.has(key)){

			const hooks = this.eventsMap.get(key)

			if(hooks && Array.isArray(hooks)) {

				hooks.push(hook)
				this.eventsMap.set(key, hooks)
				
			} else {

				this.eventsMap.set(key, [hook])
				
			}
			
		} else {

			this.eventsMap.set(key, [hook])
			
		}
		
	}

	//Rimuovi l'hook specifico
	deleteEventHook(key, hook){
		
		if(this.eventsMap.has(key)){

			const hooks = this.eventsMap.get(key)
			const updated = hooks.filter(_hook => hook === _hook)

			this.eventsMap.set(key, updated)
			
		}
	}

	get mixin(){
		
		const _this = this
		
		return {
			mounted(){

				if(!_this.mixinRegistered){

					_this.registerEventListener()
					
					_this.mixinRegistered = true;

				}

			},
		}
	}


	
}

export default RemoteKeysHandler