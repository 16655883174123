<template>

    <v-row>
      <v-col style="height:200px" class="mt-3">
        
        <Loading :status="isLoading" 
                 absolute 
                 color="primary"
                 :opacity="0">
          Verifica in corso
        </Loading>

        <v-alert v-if="error" color="warning">
          {{ $t(error) }}
        </v-alert>

      </v-col>
    </v-row>

</template>

<style lang="scss" scoped>
  
</style>

<script>
  import API from '@/api'

  import utility from '@/mixins/utility'

  import { mapMutations } from 'vuex'

  import Loading from '@/components/UI/Loading'

  export default {
    name: 'SignupEmailVerification',

    mixins: [utility],

    components: 
    {
      Loading
    },

    data: () => ({
      error: null,
      isLoading: false
    }),

    computed:
    {

    },

    props:
    {
      code: {
        type: String,
        required: true
      },
      email: {
        type: String,
        required: true
      }
    },

    methods:
    {
      ...mapMutations({
        growl: 'ui/growl'
      }),

      doVerification()
      {
        let params = {
          email: this.email,
          code: this.code
        }

        this.isLoading = true

        this.error = null

        API.signup.verifyEmail(params)
                   .then(()=>{
                    
                    this.growl({
                      content: 'Il tuo account è verificato, adesso puoi effettuare la login!',
                      color: 'success'
                    })

                    this.goTo('login')

                   })
                   .catch(({ response })=>{

                    this.error = response.data.message

                    this.isLoading = false

                   })
      }
    },

    mounted()
    {
      this.doVerification()
    }
  }
</script>
