const it = {

  errors: {
    general: `Si è verificato un errore.`,
    account_not_active: `Questo account non è attivo.`,
    coupon_doesnt_exist: `Il coupon inserito non esiste`,
    credentials: `Le credenziali inserite non sono valide, per favore ricontrollale.`,
    email_not_found: `L'indirizzo email non è valido, per favore ricontrollalo`,
    email_not_verified: `Per poter accedere al tuo account devi prima verificare l'indirizzo di posta elettronica cliccando sul link contenuto nell'email che ti abbiamo inviato.`
  },
  
  login : {
    messages: {
      signupDone: `Il tuo account è pronto, effettua l'accesso per cominciare la visione!`
    }
  },

  plans: {
    every: {
      day: 'una tantum | ogni giorno | ogni {count} giorni',
      month: 'una tantum | ogni mese | ogni {count} mesi',
      year: 'una tantum | ogni anno | ogni {count} anni',
      week: 'una tantum | ogni settimana | ogni {count} settimane'
    }
  },

  signup: {
    privacy: `Zwan utilizzerà i tuoi dati per personalizzare e migliorare la tua esperienza con Business+ e per inviarti informazioni su Business+. Puoi modificare le tue preferenze di comunicazione in qualsiasi momento. Possiamo utilizzare i tuoi dati e condividerli con le aziende del gruppo Zwan come descritto nella nostra Informativa sulla privacy. Cliccando su "Continua" confermi di aver letto la nostra Informativa sulla privacy, la Policy sui cookie e la Normativa sulla privacy in UE e UK.`,
    toc: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur suscipit, ante id mattis porttitor, ante urna feugiat turpis, vitae dapibus magna odio accumsan enim. Aenean tincidunt massa non aliquet dictum. Aliquam accumsan tempus egestas. Duis ornare ipsum velit, ut gravida augue commodo eget. Ut iaculis neque auctor molestie semper. Donec mollis magna nisi. Proin molestie, neque sed auctor hendrerit, ipsum erat consectetur elit, ac molestie dolor quam at massa. Nulla eleifend pharetra dolor, eget feugiat massa tincidunt id. Cras porta eget urna ac iaculis.
          Donec tempor urna sed mauris fermentum, ut sodales nisi euismod. Curabitur sed ultricies erat. Nam elementum quam augue, ut egestas odio cursus eget. Nunc dapibus leo tortor, vel pulvinar lectus lobortis at. Ut vitae odio nec dui volutpat consectetur. Integer sed sem in sapien tempor tempus vitae in est. Fusce adipiscing venenatis varius. Interdum et malesuada fames ac ante ipsum primis in faucibus.
          Duis eu nisi nunc. Suspendisse condimentum lobortis lacinia. Duis facilisis a nisl id tristique. Nam mollis lorem arcu, vel condimentum est malesuada sit amet. Praesent sit amet vestibulum quam, quis laoreet neque. Nunc congue placerat libero, non egestas nunc porta eu. Pellentesque et pulvinar eros. Cras eu ipsum at felis dictum fermentum at ac augue. Duis tincidunt consectetur pulvinar. Aenean nisl massa, varius sed ante nec, mattis aliquet neque. Nulla mauris lorem, ultricies in dictum in, faucibus ut tortor. Aliquam varius vehicula nisl, id interdum velit gravida at. Aliquam pharetra est nec nibh lacinia auctor et quis mauris. Phasellus a malesuada tellus, sit amet eleifend leo. Suspendisse mi ipsum, sollicitudin et blandit at, placerat et mauris. Pellentesque venenatis id magna id rutrum.
          Etiam molestie et nunc at feugiat. Donec sed placerat dolor, nec blandit felis. Donec semper arcu sit amet luctus convallis. Ut arcu odio, pretium eu commodo in, mollis eu ante. Donec molestie dolor vel cursus rutrum. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Cras sed rutrum nunc. Aliquam tincidunt tortor elit, accumsan suscipit nulla adipiscing et. Vestibulum quis elit nisl. Aliquam ac molestie quam. Vestibulum consequat pulvinar sem sed ullamcorper. Phasellus aliquam, orci eu laoreet posuere, lectus lorem ultrices arcu, et dapibus arcu ante vel nulla. Sed auctor consectetur libero non fermentum. Cras vel odio libero. Donec luctus hendrerit nunc quis aliquam. Etiam gravida ultricies ligula sit amet sodales.
          Quisque malesuada volutpat ultricies. Maecenas eleifend cursus dolor, non adipiscing ipsum dignissim vel. Cras sit amet cursus leo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Duis semper enim vitae mollis porttitor. Nulla eu elementum nisl, nec accumsan eros. In hac habitasse platea dictumst.`,
    tocError: 'Abbiamo bisogno del tuo consenso per poterti tenere aggiornato sulle novit&agrave; di Business+.'
  }

}

export default it