import API from '@/api'

const ZWAN_STORE = {
	
	FRONTEND_URL: process.env.VUE_APP_ZWAN_STORE_URL,
	
	url( endpoint = "", params = {} )
	{
		let baseUrl = this.FRONTEND_URL

		if( baseUrl.substr(-1) != '/' )
			baseUrl += '/'

		let url = baseUrl + endpoint,
			query = []

		// query string params
		for (var key in params)
			query.push(encodeURIComponent(key) + "=" + encodeURIComponent(params[key]))

		if( query.length )
			url += '?' + query.join('&')

		return url
	},

	payload( data={} )
	{
		return API.store.payload(data)
	},

	signup( params = {} )
	{
		const productSlug = process.env.VUE_APP_ZWAN_STORE_SIGNUP_PRODUCT,
				url = this.url(`product/${productSlug}`, params)

		return url
	}
}

export default ZWAN_STORE