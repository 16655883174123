<template>
   <v-btn @click="goTo('home')" text plain class="logo-wrapper">
            
      <component :is="logoVariant" :height="height"/>

    </v-btn>
</template>

<style lang="scss" scoped>
   .logo-wrapper {
    -webkit-transition: all .3s;
    transition        : all .3s;
   }
</style>

<script>
  import utility from '@/mixins/utility'

  import LogoSvg from '@/assets/images/logo/logo.svg'
  import LogoWhite from '@/assets/images/logo/logo-white.svg'

  export default {
    name: 'Logo',

    mixins: [utility],

    components: {
      LogoSvg,
      LogoWhite
    },

    data: () => ({
      
    }),

    props: {
      height: {
        type: Number,
        default: 50
      },
      white: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      logoVariant()
      {
        if( this.white )
          return 'LogoWhite'

        return 'LogoSvg'
      }
    }
  }
</script>
