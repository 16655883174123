<template>
  <div class="video-container">

    <div v-if="show && !loading">

      <div v-if="show.isPublished">
        <VideoPlayer v-if="show" 
                     :show="show"/>
      </div>

      <v-container v-else class="py-10">
        <v-row class="warning">
          <v-col>
            <v-btn fab @click="goTo('home')" 
                       :elevation="0"
                       class="mr-5">
              <v-icon>mdi-home</v-icon>
            </v-btn>
            Ops! Questo video non &egrave; ancora disponibile.
          </v-col>
        </v-row>
      </v-container>

    </div>

    <v-container class="py-10" v-if="showError">
      <v-row class="error">
        <v-col>
          <v-btn fab @click="goTo('home')" 
                     :elevation="0"
                     class="mr-5 red darken-1">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          Ops! C'&egrave; stato un errore con il caricamento del video.
        </v-col>
      </v-row>
    </v-container>

  </div>
</template>

<style lang="scss" scoped>  
  
</style>

<script>
import utility from '@/mixins/utility'

import API from '@/api'

import VideoPlayer from '@/components/video/Player3'
import { mapMutations } from 'vuex'

export default {
  name: 'Video',
  
  mixins: [utility],
  
  components: {
    VideoPlayer
  },
  
  data: () => ({
    mainShow: null,
    show: null,
    showError: false,
    loading: true,
  }),
  computed:
  {
    videoHash()
    {
      return this.$route.params.videoHash
    }
  },

  mounted()
  {
    this.load()

  },

  methods:
  {
    ...mapMutations({
      setLoading: 'ui/setLoading'
    }),

    load()
    {var videoHash = this.videoHash
      this.setLoading(true)
       API.shows.fromVideo(videoHash)
               .then((res)=>{
                //const showData = this.mapModel(res.data.data)
                console.log(res, "show data", this.show)
                this.show = this.mapModel(res.data.data)
                this.setLoading(false)
                this.mainShow = res
                this.loading = false
               })
               .catch((err)=>{
                 console.log("error", err)
                this.setLoading(false)
                this.loading = false
                this.showError = true

               })
    },

    loadMainShow()
    {
      this.mainShow = null

      if(this.show && this.show.isEpisode)
        API.shows.one(this.show.serie.showSlug)
               .then((res)=>{
                console.log(this.show)
                this.mainShow = this.mapModel(res.data.data)

               })
    }
  },

  watch:
  {
    show(show)
    {
      if(!show.canPlay) this.goTo('login')
      this.loadMainShow()
    }
  },

  metaInfo()
  {
    return {
      meta: [
        { name: 'robots', content: 'noindex,nofollow' },
      ]
    }
  }

}
</script>
