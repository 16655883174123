import _ from 'lodash'

import SETTINGS from '@/plugins/settings'

import Cast from '@/models/cast'
import Collection from '@/models/collection'
import Genre from '@/models/genre'
import PaymentMethod from '@/models/paymentMethod'
import Plan from '@/models/plan'
import Price from '@/models/price'
import Serie from '@/models/serie'
import Show from '@/models/show'
import Subscription from '@/models/subscription'
import Tag from '@/models/tag'

const Utility = {

  computed:
  {
    appName()
    {
      return SETTINGS.APP_NAME
    },

    isMobile()
    {
      return this.$vuetify.breakpoint.smAndDown
    },

    isSmartphone()
    {
      return this.$vuetify.breakpoint.xs
    }
  },

  methods: {

    goTo(where)
    {
       if( typeof where == 'string' )
         where = { name:where }

       this.$router.push(where).catch(()=>{})
    },

    goToSmart(item)
    {
        // detect where to go based on item model
        var slug = item.slug

        this.goTo({ name:'show', params:{slug} })
    },

    mapModel(model, classToMap='show')
    {
      return this.mapModels([model], classToMap)[0]
    },

    mapModels(models=[], classToMap='show')
    {
      return _.map(models, (model)=>{

        switch(classToMap){
          case 'cast': return new Cast(model)
          case 'collection': return new Collection(model)
          case 'genre': return new Genre(model)
          case 'paymentMethod': return new PaymentMethod(model)
          case 'plan': return new Plan(model)
          case 'price': return new Price(model)
          case 'serie': return new Serie(model)
          case 'show': return new Show(model)
          case 'subscription': return new Subscription(model)
          case 'tag': return new Tag(model)
          default: return model
        }

      })
    },

    scrollTo(el) 
    {
      this.$vuetify.goTo( `#${el}`, { offset: 60 })
    },

    storageUrl(append="")
    {
      return `${SETTINGS.BACKEND_URL}storage/${append}`
    }

  }
}

export default Utility