<template>
  <div>
   <SlideGroup :items="shows" 
               button-label="Vedi tutti" 
               @button="goTo({ name:'collection', params:{ slug:theCollection.slug } })"
               :title="theCollection.name"
               v-if="theCollection.isNotEmpty"
               :layout="theCollection.layout"/>
  </div>
</template>

<style type="text/css" scoped>
   
</style>

<script>
  import utility from '@/mixins/utility'

  import API from '@/api'

  import Collection from '@/models/collection'

  import SlideGroup from '@/components/slideshows/SlideGroup'

  export default {
    name: 'Collection',

    mixins: [utility],

    components: {
      SlideGroup
    },

    data: () => ({
      theCollection: {}
    }),

    props: {
      collection: {
        type: Collection,
        default: null
      },
      slug: {
        type: String,
        default: ''
      }
    },

    computed: {
      shows()
      {
        return this.theCollection.shows
      }
    },

    methods: {
      load()
      {
        let ws

        if( this.collection )
          return this.theCollection = this.collection

        else if( this.slug )
          ws = API.collections.one(this.slug)

        else
          ws = API.collections.random()

        ws.then((res)=>{
          this.theCollection = this.mapModel(res.data.data, 'collection')
        })
      }
    },

    watcher:
    {
      collection()
      {
        this.load()
      },
      slug()
      {
        this.load()
      }
    },

    mounted()
    {
      this.load()
    }
  }
</script>
