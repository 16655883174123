import _ from 'lodash'

import { mapGetters, mapActions } from 'vuex'

const Watchlist = {
  
  computed: {
    ...mapGetters([
      'favourites',
    ])
  },

  methods: {

    ...mapActions([
      'toggleFav',
    ]),
    
    isFav(showId)
    {
      let isFav = _.find(this.favourites, { id:showId })

      return isFav ? true : false
    }

  }
}

export default Watchlist