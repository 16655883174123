import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

const theme = {
		dark: true,
		themes: {
			light: {
				primary: '#3f51b5',
				secondary: '#b0bec5',
				anchor: '#8c9eff',
			},
		},
	}

export default new Vuetify({
	theme
});

export { theme }