<template>
  <!-- Log-in  -->
    <section class="position-relative pb-0">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="text-center">

                        <p class="mb-10">
                            <Logo white :height="70"/>
                        </p>

                        <v-form name="pms_login" 
                              id="pms_login"
                              :autofocus="true"
                              v-focus-section
                              :disabled="isLoading">
                            <v-alert type="info"
                                     v-if="requestMessage"
                                     class="mb-10">
                                {{ requestMessage }}
                            </v-alert>

                            <h4>Accedi</h4>
                            
                            <!-- email -->
                            <input v-focus 
                                   type="text" 
                                   placeholder="Indirizzo email" 
                                   v-model="credentials.email" 
                                   class="v-input__slot"
                                   :autofocus="true"
                                   ref="email"
                                   @keyup="$v.credentials.email.$touch()"
                                   @keyup.enter="login"
                                   @keyup.left="arrowLeft('email')"
                                   @keyup.right="arrowRight('email')"/>
                            
                            <!-- password -->
                            <input v-focus 
                                   type="password" 
                                   placeholder="Password" 
                                   v-model="credentials.password" 
                                   class="v-input__slot"
                                   ref="password"
                                   @keyup="$v.credentials.password.$touch()"
                                   @keyup.enter="login"
                                   @keyup.left="arrowLeft('password')"
                                   @keyup.right="arrowRight('password')"/>
                                    
                            <p class="login-submit text-center my-5">
                                <v-btn tile x-large color="mr-4" v-focus
                                    @click="goHome">
                                  Indietro
                                </v-btn>
                                <v-btn tile x-large color="primary" v-focus
                                    @click="login"
                                    :disabled="!canSubmit">
                                  Login
                                </v-btn>
                            </p>
                            


                            <Loading :status="isLoading"/>
                            
                        </v-form>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Log-in  -->
</template>

<script>
import _ from 'lodash'
import utility from '@/mixins/utility'

import { mapMutations } from 'vuex'

import Loading from '@/components/UI/Loading'
import Logo from '@/components/UI/Logo'

import ZWAN_STORE from '@/plugins/zwanStore'

import { required, email } from 'vuelidate/lib/validators'

export default {
  
  name: 'Login',

  mixins: [utility],

  components: {
    Loading,
    Logo
  },

  data: () => ({
    credentials: {
        email: null,
        password: null
    },
    isLoading: false,
    showPassword: false
  }),

  validations: {
    credentials: {
        email: {
            required,
            email
        },
        password: {
            required
        }
    }
  },

  computed:
  {
    canSubmit()
    {
        return ( this.credentials.email && 
                 this.credentials.password &&
                 !this.$v.credentials.$anyError )
    },

    requestMessage()
    {
        let messageCode = this.$route.query.message

        return messageCode ? this.$t(`login.messages.${messageCode}`) : null
    }
  },

  methods:
  {
    ...mapMutations({
      growl: 'ui/growl'
    }),
    
    goHome(){
      this.goTo("home")
    },

    login()
    {
        if( !this.canSubmit )
            return

        this.isLoading = true
    
        this.$store.dispatch('login', this.credentials)
                   .then(()=>{
                    this.goTo('home')
                   })
                   .catch((err)=>{
                    // something went wrong here!
                    let message = err.response.data.message

                    if( _.isArray(message) )
                        message = message[0]

                    this.growl({
                        content: this.$t(message),
                        color: 'warning'
                    })

                   })
                   .finally(()=>{

                    this.isLoading = false

                   })
    },

    getPassword()
    {
        const url = ZWAN_STORE.url('reset-password')

        location.href = url
    },

    arrowLeft(field)
    {
        const el = this.$refs[field],
              pos = el.selectionStart,
              newPos = pos == 0 ? 0 : (pos-1)

        el.setSelectionRange(newPos, newPos)
    },

    arrowRight(field)
    {
        const el = this.$refs[field],
              pos = el.selectionStart,
              newPos = (pos+1)

        if( pos < el.value.length )
            el.setSelectionRange(newPos, newPos)
    },

  },

  mounted()
  {

  }

}
</script>
