<template>
  <div v-focus-section>

    <Sidebar />

    <MobileMenu ref="mobileMenu" v-if="isMobile" />

    <router-view class="pl-12" :status="status" />

  </div>
</template>

<script>
import MobileMenu from '@/components/UI/MobileMenu'
import Sidebar from '@/components/UI/Sidebar'

import utility from '@/mixins/utility'
import network from '@/mixins/network'

export default {
  name: 'App',

  mixins: [network, utility],

  components: {
    MobileMenu,
    Sidebar
  },

  mounted() {
    if (this.$route.name === 'layout')
      this.goTo('home')
  },
};
</script>
