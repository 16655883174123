import i18n from '@/plugins/vue-i18n'

class Price {

  #data = {}

  constructor(collection={})
  {  
    this.#data = collection
  }

  /**
   * Property getters.
   */
  
  get id()
  {
    return this.#data.id
  }

  get currency()
  {
    return this.#data.currency
  }

  get every()
  {
    return this.#data.every
  }

  get name()
  {
    return this.#data.name
  }

  get recurrency()
  {
    return this.#data.recurrency
  }

  get symbol()
  {
    return this.#data.symbol
  }

  get value()
  {
    return this.#data.value
  }

  /**
   * Computed properties.
   */
  
  get fullValue()
  {
    let text = `${this.value} ${this.symbol}`

    return text
  }
  
  get recurrencyText()
  {
    let namespace = `plans.every.${this.recurrency}`

    return i18n.tc( namespace, this.every )
  }

}

export default Price