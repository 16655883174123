import Api from './'

const favourites = {

	/**
	* Get current user favourites.
	*/

	list(params={})
	{
		let url = Api.getUrl('favourites')

		return Api.client().get(url, { params })
	},

	toggle( showId )
	{
		let url = Api.getUrl(`favourites/toggle/${showId}`)

		return Api.client().post(url)
	},

	clearAll()
	{
		let url = Api.getUrl(`favourites/clear`)

		return Api.client().post(url)
	},

}

export default favourites