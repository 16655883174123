import axios from 'axios'

import SETTINGS from '@/plugins/settings'

import auth from './auth'
import casts from './casts'
import collections from './collections'
import ecommerce from './ecommerce'
import favourites from './favourites'
import genres from './genres'
import shows from './shows'
import signup from './signup'
import store from './store'
import tags from './tags'
import EventBus from '@/plugins/events'


const client = axios.create()
client.interceptors.response.use(config => {
	return config
}, (error) => {
	EventBus.$emit("network-error")
	return Promise.reject(error)
})


const apiConfig = {
	url: SETTINGS.BACKEND_URL,
	namespace: 'api',
	version: 'v1'
}

const API = {

	/**
	 * Modules.
	 */
	auth,
	casts,
	collections,
	ecommerce,
	favourites,
	genres,
	shows,
	signup,
	store,
	tags,

	client() {
		return client
	},

	getUrl(endpoint) {
		let apiUrl = apiConfig.url

		return `${apiUrl}${apiConfig.namespace}/${apiConfig.version}/${endpoint}/`
	},

	/**
	 * Search.
	 */

	search(s, params = {}) {
		let url = this.getUrl(`search/${s}`)

		return this.client().get(url, { params })
	},

	/**
	 * Auth.
	 */

	login(credentials = {}) {
		let url = this.getUrl('login')

		return this.client().post(url, credentials)
	},

	user() {
		let url = this.getUrl('user')

		return this.client().get(url)
	}

}

export default API