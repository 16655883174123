<template>
  <!-- Log-in  -->
    <section class="position-relative pb-0">
        <div class="gen-login-page-background" style="background-image: url('@/skin/images/background/asset-54.jpg');"></div>
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="text-center">

                        <p class="mb-10">
                            <Logo white :height="70"/>
                        </p>

                        <form name="pms_login" id="pms_login">

                            <h4>Verifica il tuo indirizzo di posta elettronico</h4>

                            <EmailVerifier 
                                v-if="requestVerificationCode"
                                :email="requestEmail"
                                :code="requestVerificationCode"/>

                            <EmailSender :email="requestEmail" v-else/>
                            
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Log-in  -->
</template>

<script>
import utility from '@/mixins/utility'

import Logo from '@/components/UI/Logo'
import EmailVerifier from '@/components/signup/EmailVerification'
import EmailSender from '@/components/signup/SendEmailVerification'

export default {
  
  name: 'EmailVerification',

  mixins: [utility],

  components: {
    EmailSender,
    EmailVerifier,
    Logo
  },

  data: () => ({

  }),

  computed:
  {
    requestEmail()
    {
        let email = this.$route.query.email

        return email
    },

    requestVerificationCode()
    {
        let verificationCode = this.$route.query.code

        return verificationCode
    }
  },

  methods: {

  },

  watch:
  {
    
  },

  mounted()
  {
    
  }

}
</script>
