const state = {
    stripe: null,
}

const getters = {

    stripe(state)
    {
        return state.stripe
    }

}

const actions = {

    initStripe({ commit }, Stripe)
    {
        commit('setStripe', Stripe)
    },

}

const mutations = {
    
    setStripe(state, Stripe)
    {
        state.stripe = Stripe
    },

}

const ecommerce = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

export default ecommerce