<template>
  <div>

    <SignupStore/>

    <v-container class="mt-10">

      <!-- Multi-device -->

      <v-row class="mt-7" align="center">
        <v-col :cols="isMobile ? 12 : 6">
          <v-img contain src="mockup.png"/>
        </v-col>
        <v-col :cols="isMobile ? 12 : 6">
          <h1 class="mt-7">
            Il business come non lo avevi mai visto
          </h1>
          <ul class="mt-4">
            <li v-for="(item,i) in keyPoints" 
                :key="i"
                class="text-h5 mb-2"
                v-html="item"></li>
          </ul>
        </v-col>
      </v-row>

      <v-row class="text-center" id="devices">

        <v-col cols="12">
          <h1 class="mt-7">
            Vedi Business+ dove preferisci
          </h1>
        </v-col>
        
        <v-col v-for="(device,i) in devices"
               :key="i">
          <v-icon class="text-h1">mdi-{{ device.icon }}</v-icon>
          <p class="text-h4"
             v-html="device.title">
          </p>
          <v-list class="transparent">
            <v-list-item v-for="(item, j) in device.list"
                         :key="j">
              <v-btn text block
                :href="item.href" 
                target="_blank"
                :disabled="item.disabled">
                <v-icon class="mr-1"
                        v-if="item.disabled">
                      mdi-timer-sand
                </v-icon>
                {{ item.title }}
              </v-btn>
            </v-list-item>
          </v-list>
        </v-col>

      </v-row>


      <!-- FAQ -->

      <v-row class="mt-7">

        <v-col cols="12">
          <h1 class="mt-7 text-center">Domande frequenti</h1>
        </v-col>

        <v-col cols="12">
          
          <v-expansion-panels>
            <v-expansion-panel
              v-for="(faq,i) in faqs"
              :key="i"
            >
              <v-expansion-panel-header>
                <span class="text-h5">
                  {{ faq.title }}
                </span>
              </v-expansion-panel-header>
            <v-expansion-panel-content>
              {{ faq.content }}
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        </v-col>
      </v-row>

    </v-container>

  </div>
</template>

<script>
import DEVICES from '@/plugins/devices'

import utility from '@/mixins/utility'

import SignupStore from '@/components/signup/SignupStore'

import { mapMutations } from 'vuex'

export default {
  
  name: 'About',

  mixins: [ utility ],

  components: {
    SignupStore
  },

  data: () => ({
    devices: DEVICES,
    faqs: [
      {
        title: "Che cos'è Business+?",
        content: "Business+ è l'unica TV dedicata all'intrattenimento business! Grazie alle produzioni originali Business+ e alle nostre partnership, troverai ogni mese nuovi contenuti e idee per il tuo business, raccontati come mai prima d'ora."
      },
      {
        title: "Cosa posso guardare su Business+?",
        content: "Su Business+ troverai talk show, documentari e serie TV dedicati ai temi davvero importanti per il tuo business: innovazione, leadership, nuovi mercati, tecnologia e marketing, su Business+ c'è tutto questo e molto di più!"
      },
      {
        title: "Necessito di un abbonamento per vedere i contenuti?",
        content: "Dipende! Alcuni contenuti grazie alle nostre partnership sono in visione gratuita, mentre i contenuti originali Business+ richiedono un abbonamento attivo per essere visti."
      },
      {
        title: "Come posso abbonarmi a Business+?",
        content: "Cliccando sul pulsante \"Abbonati ora\" verrai portato sullo Zwan Store dove potrai acquistare il tuo abbonamento Business+ pagando con carta di credito. Se se già un abbonato Z-One invece, Business+ è compreso nel tuo abbonamento!"
      },
      {
        title: "Dove posso vedere Business+?",
        content: "Puoi vedere Business+ con il tuo browser, sia da desktop che da mobile, oppure scaricando le nostre applicazioni puoi vederlo anche su dispositivi iOS e Android, e sulle Smart TV LG, Samsung e Amazon Firestick. Le applicazioni Business+ verranno rilasciate nel corso del 2022, segui i profili Zwan e Business+ sui social network per restare sempre aggiornato."
      }
    ],
    keyPoints: [
      `È nata la tv digitale dedicata al mondo dell’economia e che si rivolge a manager e imprenditori.`,
      `L'unico servizio streaming che fornisce contenuti di <em>infotainment</em> a tema business.`,
      `Una piattaforma che promuove l’interazione fra pubblico e produzione: vuoi proporre un format in linea con la tua attività? Contattaci!`,
      `Uno spazio sempre aperto al confronto fra le realtà   aziendali e gli organi istituzionali, confronto che spesso rischia  di non avere il giusto risalto nella tv generalista.`
    ]
  }),

  computed: {},

  methods:
  {
    ...mapMutations({
      setLoading: 'ui/setLoading'
    })
  },

  mounted()
  {

  }

}
</script>
