<template>

    <div>

      <Cover image="/signup-bg.jpeg"
           :opacity="0.3"/>

      <v-container>
    
        <v-row class="pt-15">

          <v-col class="text-center">
          
            <Logo white :height="100"/>

            <p class="mt-20 text-h4 primary--text">
              Tutti i contenuti per il tuo business.<br>
              Senza limiti.
            </p>

            <p class="text-h5">
              Guarda ci&ograve; che vuoi. Dove vuoi. Disdici quando vuoi.
            </p>

            <p class="text-h6">
              Guarda Business+ su Smart TV, smartphone, tablet e browser. Scegli il dispositivo che preferisci e accedi ovunque con il tuo Zwan ID.
            </p>

            <p>
              <v-btn x-large tile 
                     color="primary" 
                     class="mt-10"
                     @click="signup">
                Comincia la prova gratuita
              </v-btn>
            </p>

          </v-col>

          <v-col cols="12" md="6"></v-col>

        </v-row>

      </v-container>
      
    </div>

</template>

<style lang="scss" scoped>
  
</style>

<script>
  import utility from '@/mixins/utility'

  import ZWAN_STORE from '@/plugins/zwanStore'

  import Cover from '@/components/UI/Cover'
  import Logo from '@/components/UI/Logo'

  export default {
    
    name: 'SignupStore',

    mixins: [ utility ],

    components: 
    {
      Cover,
      Logo
    },

    data: () => ({
      
    }),

    computed:
    {

    },

    props:
    {
      
    },

    methods:
    {
      signup()
      {
        const url = ZWAN_STORE.signup()

        location.href = url
      }
    },

    mounted()
    {

    }
  }
</script>
