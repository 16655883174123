<template>
  <div v-focus-section>

    <Cover video :show="show" v-if="coverVideo"/>
    <Cover :image="coverUrl" v-else-if="coverUrl"/>

    <ShowHeader :show="show"
                :main-show="mainShow"/>

    <!-- desktop tabs -->
    <ShowTabs :show="show"
              v-if="!isMobile"/>

    <!-- mobile sections -->
    <v-container v-else>

      <!-- episodes -->
      <div v-if="show.hasEpisodes">
        
        <span class="text-h4 font-weight-bold mt-5 mb-10 text-center d-block special-font">
          Episodi
        </span>

        <EpisodesList :show="show"/>

      </div>

      <!-- details -->
      <div v-if="show.hasDetails">

        <span class="text-h4 font-weight-bold mt-10 text-center d-block special-font">
          Dettagli
        </span>
        
        <TabDetails :show="show"/>

      </div>

    </v-container>

    <SlideGroup title="Della stessa serie" 
                :items="moreEpisodes"
                v-if="moreEpisodes.length"/>

    <SlideGroup title="Ti potrebbe anche piacere..." 
                :items="moreShows"
                v-if="moreShows.length"/>

  </div>
</template>

<style lang="scss" scoped>  
  
</style>

<script>
import API from '@/api'
import _ from 'lodash'
//import { stripHtml } from 'string-strip-html'

import { mapMutations } from 'vuex'

import slugMixin from '@/mixins/slug'
import utility from '@/mixins/utility'

import ShowModel from '@/models/show'

import Cover from '@/components/UI/Cover'
import EpisodesList from '@/components/show/EpisodesList'
import ShowHeader from '@/components/show/Header'
import ShowTabs from '@/components/show/Tabs'
import SlideGroup from '@/components/slideshows/SlideGroup'
import TabDetails from '@/components/show/Tabs/Details'

export default {
  name: 'Home',
  
  mixins: [ slugMixin, utility ],
  
  components: {
    Cover,
    EpisodesList,
    ShowHeader,
    ShowTabs,
    SlideGroup,
    TabDetails
  },
  
  data: () => ({
    show: new ShowModel,
    moreShows: [],
    mainShow: null,
    tab: 0
  }),

  computed:
  {
    coverUrl()
    {
      let url

      if(this.show.bg)
        url = this.show.bg

      else if( this.mainShow )
        url = this.mainShow.bg

      return url
    },
    coverVideo()
    {
      let url

      if(this.show.bgVideo)
        url = this.show.bgVideo

      else if( this.mainShow )
        url = this.mainShow.bgVideo

      return url
    },
    hasEpisodes()
    {
      return this.episodes.length
    },
    episodes()
    {
      var episodes = []

      _.each(this.series, (serie)=>{
        episodes = episodes.concat(serie.episodes)
      })

      return episodes
    },
    hasGenres()
    {
      return this.show.genres
    },
    moreEpisodes()
    {
      if( !this.show.serie )
        return []

      return _.filter(this.show.serie.episodes, (show)=>{
        return show.id != this.show.id
      })
    },
    series()
    {
      return this.show.series
    }
  },

  mounted()
  {
    this.load()

    this.loadMoreShows()
  },

  methods:
  {
    ...mapMutations({
      setLoading: 'ui/setLoading'
    }),

    load()
    {
      var slug = this.slug

      this.mainShow = null

      this.setLoading(true)

      API.shows.one(slug)
               .then((res)=>{

                let show = this.mapModel(res.data.data)
                
                this.show = show

                // se lo show è all'interno di una serie recupero anche lo show principale
                if( show.serie )
                  API.shows.one(show.serie.showSlug)
                           .then((res)=>{
                            this.mainShow = this.mapModel(res.data.data)
                           })

                this.setLoading(false)

               })
    },

    loadMoreShows()
    {
      API.shows.list({ 'filter[not_episodes]':true })
               .then((res)=>{
                this.moreShows = this.mapModels(res.data.data)
               })
    }
  },

  metaInfo()
  {
    let ogType = this.show.isEpisode ? 'video.episode' : 'video.movie'

    let meta = [
        { name: 'description', content: this.show.excerpt },
        { name: 'og:title', content: this.show.title },
        { name: 'og:image', content: this.show.cover },
        { name: 'og:type', content: ogType },
        // twitter
        { name: 'twitter:card', content: 'player' },
        { name: 'twitter:title', content: this.show.title },
        { name: 'twitter:image', content: this.show.cover },
        //{ name: 'twitter:player', content: 'videoUrl' },
      ]

    this.show.cast.forEach((cast)=>{
      meta.push({ 
        name: 'og:video:actor', 
        content: cast.name
      })
    })

    return {
      title: this.show.title,
      meta
    }
  }

}
</script>
