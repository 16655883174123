<template>
  <v-overlay :absolute="absolute" 
             v-model="status"
             :opacity="opacity"
             :color="backgroundColor"
             :z-index="99999">
    <p class="text-center">
      <v-progress-circular
        indeterminate
        :size="size"
        :color="color"
      ></v-progress-circular>
    </p>
    <p class="text-h5 mt-5 text-center"
       v-if="$slots.default">
      <slot></slot>
    </p>
  </v-overlay>
</template>

<style type="text/css" scoped>
   
</style>

<script>
  export default {
    name: 'Loading',

    mixins: [],

    data: () => ({
      
    }),

    props: {
      status: {
        type: Boolean,
        default: false
      },
      size: {
        type: Number,
        default: 64
      },
      absolute: {
        type: Boolean,
        default: false
      },
      opacity: {
        type: Number,
        default: 0.46
      },
      color: {
        type: String,
        default: ''
      },
      backgroundColor: {
        type: String,
        default: '#212121'
      },
    },

    computed: {
      
    }
  }
</script>
