<template>
  <v-snackbar v-model="growler.status" 
              v-bind="growler"
              rounded="pill"
              class="mb-7">
    <span v-html="growler.content"></span>
  </v-snackbar>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'Growler',

    components: {},

    props: {},

    data: () => ({

    }),

    computed: {
      ...mapGetters({
        'growler': 'ui/growler'
      })
    },

    mounted(){},

    methods: {
      
    }

  }
</script>
