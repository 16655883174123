<template>
  <div>

    <v-select
      :items="seasons"
      solo
      label="Stagione"
      v-model="season"
      v-if="hasSeries"
      class="seasons-dropdown"
    ></v-select>

    <v-list color="transparent"
            v-if="hasEpisodes">
      <v-list-item v-for="(episode,i) in episodes"
                   :key="i"
                   @click="goToSmart(episode)">
        
        <v-list-item-avatar 
            tile 
            width="100" 
            height="50">
          <v-img :src="episode.cover"></v-img>
        </v-list-item-avatar>
        
        <v-list-item-title>
          <span :class="listItemCss">
            {{ episode.title }}
          </span>
        </v-list-item-title>

      </v-list-item>
    </v-list>

  </div>
</template>

<style lang="scss" scoped>  
  
</style>

<style lang="scss">  
  .seasons-dropdown .v-select__slot input {
    display: none;
  }
</style>

<script>
  import utility from '@/mixins/utility'
  
  export default {
    name: 'EpisodesList',

    mixins: [ utility ],

    components: {
      
    },

    data: () => ({
      season: 0
    }),

    props: {
      show:
      {
        default: null
      }
    },

    computed:
    {
      episodes()
      {
        let season = this.season,
            serie = this.series[season]
            
        return serie ? serie.episodes : []
      },
      hasEpisodes()
      {
        return this.episodes.length > 0
      },
      hasSeries()
      {
        return this.series.length > 1
      },
      listItemCss()
      {
        return this.isMobile ? 'text-wrap' : 'text-h6 text-wrap'
      },
      seasons()
      {
        return this.series.map((serie,i)=>{

          return { text:serie.name, value:i }

        })
      },
      series()
      {
        return this.show.series
      }
    },

    methods:
    {
      
    }

  }
</script>
