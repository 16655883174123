<template>
   <span><!-- Spatial Navigation --></span>
</template>

<style lang="scss" scoped>
   
</style>

<script>
//import goTo from 'vuetify/lib/services/goto'
import { mapMutations } from 'vuex'

export default {
    name: 'SpatialNavigation',

    mixins: [],

    components: {
      
    },

    data: () => ({
      prevSound: null,
      soundEffect2: null
    }),

    methods:
    {
      ...mapMutations({
        forceSidebar: 'ui/forceSidebar'
      }),

      bindEvents()
      {
        window.addEventListener('sn:enter-up', this.onEnter)
        window.addEventListener('sn:focused', this.onFocus)
        window.addEventListener('sn:unfocused', this.onBlur)
      },

      onEnter(ev)
      {
        if(ev.target.attributes['spatial-ignore']) return

        ev.target.click()
      },

      onBlur(ev)
      {
        let t = ev.target

        t.classList.remove('focused')
      },

      onFocus(ev) // ev
      {

        let t = ev.target, 
            isSidebarLink = t.classList.contains('sidebar-link');

        if(isSidebarLink)
          this.forceSidebar()
        else
          this.forceSidebar(false)

        t.classList.add('focused')

        //goTo( ev.srcElement, { offset } )
      }
    },

    mounted()
    {
      this.bindEvents()
    }
  }
</script>
