<template>
  <div>
    
    <!-- special keys -->
    <v-row v-focus-section>
      <v-col>
        <v-btn block 
               x-large
               v-focus
               @click.stop="key(' ')"
               :disabled="disabled"
               class="dark-blue"
               ref="button_space">
          <v-icon>
            mdi-keyboard-space
          </v-icon>
        </v-btn>
      </v-col>
      <v-col>
        <v-btn block 
               x-large
               v-focus
               @click.stop="remove"
               :disabled="disabled"
               class="dark-blue"
               ref="button_remove">
          <v-icon>
            mdi-backspace
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-container class="d-flex p-0 dark-blue mt-5 flex-column" v-focus-section>

      <div class="d-flex flex-wrap p-2 justify-content-center">
            <v-btn v-for="(letter, i) in letters" :key="'letter'+i"
                  v-focus text
                   class="text-h5 text-lowercase font-weight-bold"
                   @click.stop="key(letter)"
                   :disabled="disabled"
                   :ref="`button_${letter}`">
              {{ letter }}
            </v-btn>
      </div>

      <v-divider class="my-3"/>

      <div class="d-flex flex-wrap p-2 justify-content-center">
            <v-btn v-for="(number, i) in numbers" :key="'number'+i"
                  v-focus text
                   class="text-h5 text-lowercase font-weight-bold"
                   @click.stop="key(number)"
                   :disabled="disabled"
                   :ref="`button_${number}`">
              {{ number }}
            </v-btn>
      </div>

    </v-container>

  </div>
</template>

<style lang="scss" scoped>
  
</style>

<script>
  export default {
    name: 'Keyboard',

    mixins: [],

    data: () => ({
      disabled: false,
      timeout: null
    }),

    props: {
      
    },

    methods: {

      setTimeout(key)
      {
        this.disabled = true

        this.timeout = setTimeout(() => {
          
          this.disabled = false

          if( key === ' ' )
            key = 'space'

          const ref = this.$refs[`button_${key}`],
                el = ref.$el || ref[0].$el

          this.$nextTick(() => el.focus())

        }, 200)
      },
      
      key(key)
      {
        this.setTimeout(key)

        this.$emit('key', key)
      },

      remove()
      {
        this.setTimeout('remove')

        this.$emit('remove')
      }

    },

    watch: {
      
    },

    computed: {
      
      letters()
      {
        let letters = [],
            i = 0

        for( i = 0; i < 26; i++ )
          letters.push( String.fromCharCode(65 + i) )

        return letters
      },

      numbers()
      {
        let numbers = [0],
            i = 0

        for( i = 1; i < 10; i++ )
          numbers.push(i)

        return numbers
      }

    },

    mounted()
    {
      
    },

    beforeDestroy()
    {
     clearTimeout(this.timeout)
    }
  }
</script>
