import useVuelidate from '@vuelidate/core'

import { helpers, minLength } from '@vuelidate/validators'

/**
 * Custom validators.
 */

const passwordMinLength = helpers.withMessage(
      () => `La password deve essere lunga almeno 8 caratteri.`,
      minLength(8)) // minimum password length.

const specialCharacters = ['@','+','!'],
      hasSpecialCharacters = helpers.withMessage(
      () => `La password deve contenere almeno uno di questi caratteri speciali: ${specialCharacters.join(', ')}.`,
      (value)=>{
        let check = false
        
        specialCharacters.forEach((char)=>{
            if(value.indexOf(char) !== -1)
              check = true
          })

        return check
      })

const Vuelidate = {

  setup()
  {
    return { v$: useVuelidate() }
  },
  
  computed: {
    canSubmit()
    {
      return this.v$.$dirty && 
             this.v$.$errors.length === 0
    },

    firstFormError()
    {
      let errors = this.v$.form.$errors

      if(!errors.length)
        return null

      return errors[0].$message
    }
  },

  methods: {
    inputErrorClass(fieldName)
    {
      return this.v$.form[fieldName].$error &&
             this.v$.form[fieldName].$dirty
    }
  },

  watch: {

    form: {
      deep: true,
      handler()
      {
        this.v$.form.$touch()
      }
    }

  },

  customValidators:
  {
    hasSpecialCharacters,
    passwordMinLength
  }

}

export default Vuelidate