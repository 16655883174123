const appStore = "https://apps.apple.com/it/app/business/id1615978890",
      playStore = "https://play.google.com/store/apps/details?id=it.zwan.businesstv"

const DEVICES = [
      {
        icon: 'television',
        title: 'Smart TV',
        list: [
          { title: 'Samsung', href: "https://www.google.com", disabled: true },
          { title: 'TV LG', href: "https://www.google.com", disabled: true },
          { title: 'Dispositivi con Android TV', href: "https://www.google.com", disabled: true },
          { title: 'Amazon Fire TV', href: "https://www.google.com", disabled: true }
        ]
      },
      {
        icon: 'apple-ios',
        title: 'Dispositivi<br>Apple',
        list: [
          { title: 'iPhone', href: appStore },
          { title: 'iPad', href: appStore }
        ]
      },
      {
        icon: 'android',
        title: 'Dispositivi Android',
        list: [
          { title: 'Smartphone Android', href: playStore },
          { title: 'Tablet Android', href: playStore }
        ]
      },
      {
        icon: 'laptop',
        title: 'Computer',
        list: [
          { title: 'MacOS' },
          { title: 'PC Windows' }
        ]
      }
    ]

export default DEVICES