<template>
  <div>

    <HeaderSpacer/>

    <v-container class="mt-10">

      <h1>
        Informativa sulla privacy
      </h1>

      <p>Lo scopo del presente documento è di informare la persona fisica (di seguito “<strong>Interessato</strong>”) relativamente al trattamento dei suoi dati personali (di seguito “<strong>Dati Personali</strong>”) raccolti dal titolare del trattamento, Zwan srl, con sede legale in Piazza Capponi, 13 - 00193 Roma, CF/Partita IVA 15488481001, indirizzo e-mail info@zwan.it, (di seguito “<strong>Titolare</strong>”), tramite e Business+ (di seguito “<strong>Applicazione</strong>”).</p>

      <p>Le modifiche e gli aggiornamenti saranno vincolanti non appena pubblicati sull’Applicazione. In caso di mancata accettazione delle modifiche apportate all’Informativa Privacy, l’Interessato è tenuto a cessare l’utilizzo di questa Applicazione e può richiedere al Titolare di cancellare i propri Dati Personali.</p>

      <v-list color="transparent">
        <v-list-item @click="scrollTo('data')">
          1. Categorie di Dati Personali trattati
        </v-list-item>
        <v-list-item @click="scrollTo('cookie')">
          2. Cookie e tecnologie simili
        </v-list-item>
        <v-list-item @click="scrollTo('law')">
          3. Base giuridica e finalità del trattamento
        </v-list-item>
        <v-list-item @click="scrollTo('how')">
          4. Modalità di trattamento e destinatari dei Dati Personali
        </v-list-item>
        <v-list-item @click="scrollTo('place')">
          5. Luogo
        </v-list-item>
        <v-list-item @click="scrollTo('time')">
          6. Periodo di conservazione dei Dati Personali
        </v-list-item>
        <v-list-item @click="scrollTo('rights')">
          7. Diritti dell’Interessato
        </v-list-item>
      </v-list>

      <h4 class="mt-10" id="data">
        Categorie di Dati Personali trattati
      </h4>

      <p>Il Titolare tratta le seguenti tipologie di Dati Personali forniti volontariamente dall’Interessato:</p>

      <ul>
        <li><strong>Dati di contatto</strong>: nome, cognome, indirizzo, e-mail, telefono, immagini, credenziali di autenticazione, eventuali ulteriori informazioni inviate dall'Interessato, etc. </li>
        <li><strong>Dati fiscali e di pagamento</strong>: codice fiscale, partita IVA, dati della carta di credito, estremi del conto corrente bancario, etc.</li>
      </ul>

      <p>Il Titolare tratta le seguenti tipologie di Dati Personali raccolti in maniera automatizzata:</p>

      <ul>
        <li><strong>Dati tecnici</strong>: Dati Personali prodotti dai dispositivi, dalle applicazioni, dagli strumenti e dai protocolli utilizzati, quali ad esempio, informazioni sul dispositivo utilizzato, indirizzi IP, tipo di browser, tipo di provider Internet (ISP). Tali Dati Personali possono lasciare tracce che, in particolare se combinate con identificativi univoci e altre informazioni ricevute dai server, possono essere utilizzate per creare profili delle persone fisiche.</li>
        <li><strong>Dati di navigazione e di utilizzo dell’Applicazione</strong>: quali, ad esempio, pagine visitate, numero di clic, azioni compiute, durata delle sessioni, etc. </li>
        <li><strong>Dati relativi alla posizione esatta dell’Interessato</strong>: ad esempio, dati di geolocalizzazione che identificano in modo preciso la posizione dell’Interessato che possono essere raccolti tramite la rete satellitare (es. GPS) e altri mezzi, raccolti previo consenso dell’Interessato. L’Interessato può revocare il consenso in qualsiasi momento.</li>
      </ul>

      <p>Il mancato conferimento da parte dell’Interessato dei Dati Personali per i quali sussiste l’obbligo legale, contrattuale o qualora costituiscano requisito necessario per la conclusione del contratto con il Titolare, comporterà l’impossibilità del Titolare di instaurare o proseguire il rapporto con l’Interessato.</p>
      <p>L’Interessato che comunichi al Titolare Dati Personali di terzi è direttamente ed esclusivamente responsabile della loro provenienza, raccolta, trattamento, comunicazione o diffusione.</p>

      <h4 class="mt-10" id="cookie">
        Cookie e tecnologie simili
      </h4>

      <p>
        L'Applicazione usa cookie, web beacon, identificatori univoci e altre analoghe tecnologie per raccogliere Dati Personali dell’Interessato sulle pagine, sui collegamenti visitati e sulle altre azioni che si eseguono quando l’Interessato utilizza l’Applicazione. Essi vengono memorizzati per essere poi trasmessi alla successiva visita dell'Interessato. Si può prendere visione della Cookie Policy completa al seguente indirizzo: 
        <v-btn text small color="primary">
          https://www.businessplustv.it/cookie-policy
        </v-btn>
      </p>

      <h4 class="mt-10" id="law">
        Base giuridica e finalità del trattamento
      </h4>

      <p>Il trattamento dei Dati Personali è necessario:</p>

      <ul>
        <li>
          per l'esecuzione del contratto con l’Interessato e precisamente: 
          <ol>
            <li><strong>adempimento di ogni obbligo derivante dal rapporto precontrattuale o contrattuale con l’Interessato</strong></li>
            <li><strong>registrazione e autenticazione dell’Interessato</strong>: per consentire all’Interessato di registrarsi sull’Applicazione, accedere e essere identificato anche tramite piattaforme esterne</li>
            <li><strong>supporto e contatto con l’Interessato</strong>: per rispondere alle richieste dell’Interessato </li>
            <li><strong>gestione dei pagamenti</strong>: per gestire i pagamenti tramite carta di credito, bonifico bancario o altri strumenti </li>
          </ol>
        </li>
        <li>
          per obbligo di legge e precisamente: 
          <ol>
            <li>l’adempimento di qualunque obbligo previsto dalle vigenti normative, leggi e regolamenti, in particolare, in materia tributaria e fiscale</li>
          </ol>
        </li>
        <li>
          sulla base del legittimo interesse del Titolare, per: 
          <ol>
            <li><strong>finalità di marketing via email di prodotti e/o servizi del titolare</strong> per vendere direttamente i prodotti o servizi del Titolare utilizzando l’e-mail fornita dall’Interessato nel contesto della vendita di un prodotto o servizio analogo a quello oggetto della vendita</li>
            <li><strong>gestione, ottimizzazione e monitoraggio dell’infrastruttura tecnica</strong>: per identificare e risolvere eventuali problemi tecnici, per migliorare le performance dell’Applicazione, per gestire e organizzare le informazioni in un sistema informatico (es. server, database, etc.)</li>
            <li><strong>sicurezza e antifrode</strong>: per garantire la sicurezza del patrimonio, delle infrastrutture e delle reti del Titolare</li>
            <li><strong>statistica con dati anonimi</strong>: per effettuare analisi statistiche su dati aggregati e anonimi per analizzare i comportamenti dell’Interessato, per migliorare i prodotti e/o i servizi forniti dal Titolare e meglio soddisfare le aspettative dell’Interessato </li>
          </ol>
        </li>
        <li>
          sulla base del consenso dell’Interessato, per:
          <ol>
            <li><strong>profilazione dell’Interessato per fini di marketing</strong>: per fornire all’Interessato informazioni sui prodotti e/o servizi del Titolare attraverso un trattamento automatizzato finalizzato alla raccolta di informazioni personali con lo scopo di prevedere o valutare le sue preferenze o comportamenti</li>
            <li>
              <strong>retargeting e remarketing</strong>: per raggiungere con un annuncio pubblicitario personalizzato l’Interessato che ha già visitato o ha dimostrato interesse per i prodotti e/o servizi offerti dall'Applicazione utilizzando i suoi Dati Personali. L'Interessato può effettuare l’opt-out visitando la pagina della Network Advertising Initiative all'indirizzo:
              <v-btn text 
                     color="primary"
                     small
                     target="_blank"
                     href="https://optout.networkadvertising.org/?c=1">
                https://optout.networkadvertising.org/?c=1
              </v-btn>
            </li>
            <li><strong>finalità di marketing di prodotti e/o servizi del Titolare</strong>: per inviare informazioni o materiali commerciali e/o promozionali, per effettuare attività di vendita diretta di prodotti e/o servizi del Titolare o per compiere ricerche di mercato con modalità automatizzate e tradizionali </li>
            <li><strong>finalità di marketing di prodotti e/o servizi di terzi</strong>: per inviare informazioni o materiali commerciali e/o promozionali di terzi, per effettuare attività di vendita diretta o per compiere ricerche di mercato dei loro prodotti e/o servizi con modalità automatizzate e tradizionali</li>
            <li><strong>comunicazione dei Dati Personali per finalità di marketing di terzi</strong>: per comunicare a terzi che operano nel settore Marketing e comunicazione i Dati Personali affinché li utilizzino per inviare loro informazioni o materiali commerciali e/o promozionali o per effettuare attività di vendita diretta dei loro prodotti e/o servizi o per compiere ricerche di mercato con modalità automatizzate e tradizionali</li>
            <li><strong>rilevazione della posizione esatta dell’Interessato</strong>: per rilevare la presenza dell'Interessato, per controllare gli accessi, gli orari e la presenza dell'Interessato in un determinato luogo, etc.</li>
          </ol>
        </li>
      </ul>

      <p>I Dati Personali dell'Interessato possono inoltre essere utilizzati dal Titolare per tutelarsi in giudizio avanti le sedi giudiziarie competenti.</p>

      <h4 class="mt-10" id="how">
        Modalità di trattamento e destinatari dei Dati Personali
      </h4>

      <p>Il trattamento dei Dati Personali viene effettuato mediante strumenti cartacei e informatici con modalità organizzative e con logiche strettamente correlate alle finalità indicate e mediante l'adozione di adeguate misure di sicurezza.</p>

      <p>I Dati Personali sono trattati esclusivamente da:</p>

      <ul>
        <li>persone autorizzate dal Titolare del trattamento dei Dati Personali che si sono impegnate alla riservatezza o abbiano un adeguato obbligo legale di riservatezza;</li>
        <li>soggetti che operano in autonomia come distinti titolari del trattamento o da soggetti designati quali responsabili del trattamento dal Titolare al fine di svolgere tutte le attività di trattamento necessarie a perseguire le finalità di cui alla presente informativa (ad esempio, partner commerciali, consulenti, società informatiche, fornitori di servizi, hosting provider);</li>
        <li>soggetti o enti a cui è obbligatorio comunicare i Dati Personali per obbligo di legge o per ordine delle autorità.</li>
      </ul>

      <p>I soggetti sopra elencati sono tenuti a utilizzare le garanzie appropriate per proteggere i Dati Personali e possono accedere solo a quelli necessari per eseguire i compiti a loro assegnati.</p>

      <p>I Dati Personali non verranno diffusi indiscriminatamente in alcun modo.</p>

      <h4 class="mt-10" id="place">
        Luogo
      </h4>

      <p>
        Se necessario, i Dati Personali potrebbero essere trasferiti a soggetti ubicati al di fuori del territorio dello Spazio Economico Europeo (SEE). Tutte le volte in cui i Dati Personali dovessero essere oggetto di trasferimento al di fuori del SEE, il Titolare adotterà ogni misura contrattuale idonea e necessaria a garantire un adeguato livello di protezione dei Dati Personali, inclusi - tra gli altri - gli accordi basati sulle clausole contrattuali standard per il trasferimento dei dati al di fuori del SEE, approvate dalla Commissione Europea. Per richiedere informazioni sulle specifiche garanzie adottate, l’Interessato può contattare il Titolare al seguente indirizzo e-mail <MailTo/>.
      </p>

      <h4 class="mt-10" id="time">
        Periodo di conservazione dei Dati Personali
      </h4>

      <p>
        I Dati Personali saranno conservati per il periodo di tempo necessario ad adempiere alle finalità per i quali sono stati raccolti, in particolare: 
      </p>

      <ul>
        <li>per finalità relative all’esecuzione del contratto tra il Titolare e l’Interessato, saranno conservati per tutta la durata del rapporto contrattuale e, dopo la cessazione, per il periodo di prescrizione ordinario pari a 10 anni. Nel caso di contenzioso giudiziale, per tutta la durata dello stesso, fino all’esaurimento dei termini di esperibilità delle azioni di impugnazione</li>
        <li>per finalità relative al legittimo interesse del Titolare, saranno conservati fino al compimento di tale interesse</li>
        <li>per l’adempimento di un obbligo di legge, per ordine di un’autorità e per la tutela in giudizio, saranno conservati nel rispetto delle tempistiche previste da detti obblighi, normative e comunque sino al compimento del termine prescrizionale previsto dalle norme in vigore</li>
        <li>per finalità basate sul consenso dell’Interessato, saranno conservati sino alla revoca del consenso. Per finalità di marketing per un periodo non superiore a 24 mesi. </li>
      </ul>

      <p>Al termine del periodo di conservazione, tutti i Dati Personali saranno cancellati o conservati in una forma che non consenta l’identificazione dell’Interessato.</p>

      <h4 class="mt-10" id="rights">
        Diritti dell’Interessato
      </h4>

      <p>Gli Interessati possono esercitare determinati diritti con riferimento ai Dati Personali trattati dal Titolare. In particolare, l’Interessato ha il diritto di:</p>

      <ul>
        <li>essere informato sul trattamento dei propri Dati Personali</li>
        <li>revocare il consenso in ogni momento</li>
        <li>limitare il trattamento dei propri Dati Personali</li>
        <li>opporsi al trattamento dei propri Dati Personali</li>
        <li>accedere ai propri Dati Personali</li>
        <li>verificare e chiedere la rettifica dei propri Dati Personali</li>
        <li>ottenere la limitazione del trattamento dei propri Dati Personali</li>
        <li>ottenere la cancellazione dei propri Dati Personali</li>
        <li>trasferire i propri Dati Personali ad altro titolare</li>
        <li>proporre reclamo all’autorità di controllo della protezione dei propri Dati Personali e/o agire in sede giudiziale.</li>
      </ul>

      <p>Per esercitare i propri diritti, gli Interessati possono indirizzare una richiesta al seguente indirizzo e-mail <MailTo/>. Le richieste saranno prese in carico dal Titolare immediatamente ed evase nel più breve tempo possibile, in ogni caso entro 30 giorni. </p>

      <p>Ultimo aggiornamento: <strong>29/03/2022</strong>.</p>

    </v-container>

  </div>
</template>

<script>
import utility from '@/mixins/utility'

import HeaderSpacer from '@/components/UI/HeaderSpacer'
import MailTo from '@/components/UI/MailTo'

export default {
  
  name: 'Privacy',

  mixins: [ utility ],

  components: {
    HeaderSpacer,
    MailTo
  },

  data: () => ({
    
  }),

  computed: {},

  methods:
  {
    
  },

  mounted()
  {

  }

}
</script>
