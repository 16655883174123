<template>
   <v-row class="searchbar pb-5" 
          :class="getWrapperCSS" >
     <v-col>
      <v-container>
        <input type="text" 
               placeholder="Titolo, protagonista o genere"
               :autofocus="!readonly"
               @focus="onFocus"
               @blur="onBlur"
               v-model="theSearch"
               class="transparent" 
               :readonly="readonly"/>
        <v-progress-circular
          indeterminate
          color="primary"
          :size="55"
          v-if="loading"
        ></v-progress-circular>
      </v-container>
     </v-col>
   </v-row>
</template>

<style lang="scss" scoped>
  .searchbar {
    
    background-color: rgb(75, 78, 90);
    opacity: .7;

    -webkit-transition: opacity .3s;
    transition        : opacity .3s;

    &.loading {
      input {
        padding-left: 60px;
      }
    }
    
    .container {

      position: relative;

      input {
        font-size: 44px !important;
        font-family: var(--title-fonts);

        -webkit-transition: paddingLeft .3s;
        transition        : paddingLeft .3s;

      }

      .v-progress-circular {
        position: absolute;
        left: 0px;
      }

    }

    &.is-focused {
      opacity: 1;
      ::placeholder {
        color: #fff;
      }
    }

  }
</style>

<script>
  export default {
    name: 'SearchBar',

    mixins: [],

    data: () => ({
      isFocused: false,
      theSearch: ''
    }),

    props: {
      loading: {
        type: Boolean,
        default: false
      },
      readonly: {
        type: Boolean,
        default: false
      },
      search: {
        type: String,
        default: ''
      }
    },

    methods: {
      onBlur()
      {
        this.isFocused = false
      },
      onFocus()
      {
        this.isFocused = true
      }
    },

    watch: {
      search(newValue)
      {
        this.theSearch = newValue
      },
      theSearch(newValue)
      {
        this.$emit('update:search', newValue)
      }
    },

    computed: {
      getWrapperCSS()
      {
        return { 
          'is-focused': this.isFocused,
          'loading': this.loading
        }
      }
    },

    mounted()
    {
      if(this.search)
        this.theSearch = this.search
    }
  }
</script>
