import Api from './'

const signup = {

	/**
	 * Step 1: get or create user by email.
	 */
	checkEmail(params={})
	{
		let url = Api.getUrl(`signup/email`)

		return Api.client().post(url, params)
	},

	/**
	 * Step 2: accept TOC.
	 */
	toc(params={})
	{
		let url = Api.getUrl(`signup/toc`)

		return Api.client().post(url, params)
	},	

	/**
	 * Step 3: set user password.
	 */
	password(params={})
	{
		let url = Api.getUrl(`signup/password`)

		return Api.client().post(url, params)
	},	

	/**
	 * Save marketing consense.
	 */
	marketing(params={})
	{
		let url = Api.getUrl(`signup/marketing`)

		return Api.client().post(url, params)
	},	

	/**
	 * Revoke marketing consense.
	 */
	revokeMarketing(data={})
	{
		let url = Api.getUrl(`signup/marketing`)

		return Api.client().delete(url, { data })
	},	

	/**
	 * Send email to user with verification link.
	 */
	sendVerificationCode(email)
	{
		let url = Api.getUrl('signup/verification-code')

		return Api.client().post(url, { email })
	},

	/**
	 * Verify user email.
	 */
	verifyEmail(params={})
	{
		let url = Api.getUrl(`signup/verify-email`)

		return Api.client().post(url, params)
	}

}

export default signup