<template>
    <div class="error-container">
        <v-container class="py-10">
            <v-row justify="center">
                <v-col md="5">
                    <v-row justify="center" class="mb-5">
                        <v-icon class="me-3" x-large>mdi-wifi-off</v-icon>
                    </v-row>
                    <v-row justify="center" class="text-center h4">
                         Ops... Errore di connessione
                         <div class="h6"><br/> Assicurati di essere connesso/a ad internet per proseguire</div>
                    </v-row>
                    <v-row class="my-10" justify="center">
                      <v-btn color="primary" v-focus  x-large tile @click="redirect" v-if="status === 'online'">
                         Riprova
                      </v-btn>
                     </v-row>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<style scoped>

.error-container{
    height: 100vh;
    display: flex;
    align-items: center;
}

</style>

<script>

export default{
    name: "Connection Error",
    mixins: [],
    components:{},
    props: {
        to: {
            type: String,
            default: "/home"
        },
        status: {
            type: String,
            default: 'offline'
        }
    },
    data: () => ({}),
    methods:{
        redirect(){
            this.$router.push(this.to)
        }    
    },
    mounted(){
    }
}


</script>
