<template>

<div class="player-container" 
     ref="playerContainer"  
     v-focus-section:player-section.default="{enterTo: 'last-focused'}">
    
    <div class="spinner-container" 
         v-if="isLoading">
        <v-progress-circular 
            indeterminate 
            color="primary" 
            size="125"/>
    </div>
    
    <div class="player-ui" 
         :class="{'hidden' : !showUI}">
    <!-- <div  class="player-ui" v-if="showUI" :player-section="{enterTo: 'default-element', defaultElement: '#playerButton'}"  > -->
        
        <div class="ui-row">
           <v-btn text 
                  @click.prevent="goBack" 
                  v-focus>
                <v-icon>
                    mdi-arrow-left
                </v-icon>
            </v-btn>
            <v-btn text>
                {{show.title}}
            </v-btn>
        </div>

        <div class="ui-row">
            
            <v-btn text 
                   @click.prevent="() => moveInTime('start')" 
                   v-focus 
                   ref="FullBackButton" 
                   id="FullBackButton">
                <v-icon>
                    mdi-skip-backward-outline
                </v-icon>
            </v-btn>
            
            <v-btn text 
                   @click.prevent="() => moveInTime('back')" 
                   v-focus 
                   ref="backButton" 
                   id="backButton">
                <v-icon>
                    mdi-rewind-outline
                </v-icon>
            </v-btn>
            
            <v-btn text 
                   @click.prevent="togglePlayStatus" 
                   v-focus 
                    spatial-ignore="true"
                   ref="playerButton" 
                   id="playerButton">
                <v-icon v-if="!isPlaying">
                    mdi-play
                </v-icon>
                <v-icon v-else>
                    mdi-pause
                </v-icon>
            </v-btn>

            <v-btn text 
                   @click.prevent="() => moveInTime('forward')" 
                   v-focus 
                   ref="forwardButton" 
                   id="forwardButton">
                <v-icon>
                    mdi-fast-forward-outline
                </v-icon>
            </v-btn>

            <v-btn text 
                   @click.prevent="() => moveInTime('end')" 
                   v-focus 
                   ref="endButton" 
                   id="endButton">
                <v-icon>
                    mdi-skip-forward-outline
                </v-icon>
            </v-btn>
            
            <v-slider class="ui-grow"
                min="0"
                max="100"
                :value="currentPercentage"
                thumb-label>
                <template v-slot:thumb-label>
                    {{percentageToTime(currentPercentage)}}
                </template>
            </v-slider>
            
            <v-btn text small>
                {{playerStatus}}
            </v-btn>
            
            <!--
            <v-btn text 
                   small 
                   v-focus 
                   @click.prevent="toggleSpeedRate">
                {{currentSpeedRate.key}}
            </v-btn>
            -->
            
        </div>
    </div>
    
    <div class="video-container">
       <video 
            autobuffer="true"
            ref="videoPlayer"
            preload="true"
        ></video> 
    </div>
    
</div>
 
</template>

<style lang="scss" scoped>

.hidden{
    display: none !important;
}

.player-container{
    position: absolute;
    background: black;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    flex-direction: column
}

.spinner-container{
    position: fixed;
    top: 0; bottom: 0;
    left: 0; right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.video-container{
    flex: 1;
    height: 100%;
    & video{
        width: 100%;
        height: 100%;        
    }    
}

.player-ui{
    z-index: 101;
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & .ui-grow{
        flex-grow: 1;
    }

    & .ui-row{
        display: flex;
        gap: 10px;
        padding: 10px;
        background: rgb(24, 32, 49);
        flex-shrink: 0;
        z-index: 1000;
        height: 50px;
        align-items: center;
    }
}
</style>


<script>
import utility from '@/mixins/utility'
import network from '@/mixins/network'

let player;
let UITimerStarted = false;
let UITimeout;

const prefixZero = val => String(val).length === 1 ? `0${val}` : val

const timeToHMS = seconds => ({
    h: prefixZero(Math.floor(seconds / 60 / 60)),
    m: prefixZero(Math.floor(seconds / 60) % 60),
    s: prefixZero(seconds % 60)
})

function secondsToTimeString(time, duration=0)
{
    const seconds = duration > 0 ? Math.round(duration-time) : Math.round(time)
    const {h,m,s} = timeToHMS(seconds)
    return `${h}:${m}:${s}`
}


export default {
    name: 'VideoPlayer',

    mixins: [network, utility],      
    data: () => ({
        src: null,
        isLoading: true,
        isPlaying: false,
        videoDuration: 0,
        currentTime: 0,
        currentPercentage: 0,
        currentSpeedRateIndex: 0,
        jumpedTime: false,
        UIToggleDelay: 5000,
        showUI: false,
        timeOut: null,
        speedRates:[
            {key: "1X",value: 1},
            {key: "1.5X", value: 1.5},
            {key: "2X",value: 2},
            {key: "0.5X",value: 0.5},
            {key: "0.75X", value: 0.75}
        ]
    }),
    
    props: {
        show: {
            type: Object,
            default: ()=>({})
        }, trailer: {
            type: Boolean,
            default: false
        }
    },
    mounted(){
        player = this.videoPlayer
        player.src = this.videoSrc
        player.poster = this.show.bg

        // this.toggleFullScreen()

        //playerContainer.requestFullscreen()
        player.oncanplay = () => {
            this.showUI = false
            this.videoDuration = player.duration
            this.isLoading = false;
            this.isPlaying = true

            console.log("IS PLAYING")
        }

        player.ontimeupdate = () => {
            this.currentTime = player.currentTime;
            this.currentPercentage = (this.currentTime / this.videoDuration * 100).toFixed(2)
            if(this.jumpedTime){
                this.jumpedTime = false
                this.showUI = true
                clearTimeout(UITimeout)
            }
        }

        player.waiting = () => {
            this.isLoading = true
            this.isPlaying = false
        }
        document.addEventListener("keydown", this.onKeyDown, true)

        this.$addRemoteKeyListener("MediaPlayPause", this.handleRemotePlayPause)
        this.$addRemoteKeyListener("MediaPlay", this.handleRemotePlay)
        this.$addRemoteKeyListener("MediaPause", this.handleRemotePause)

        this.$addRemoteKeyListener("MediaRewind", () => this.moveInTime("back"))
        this.$addRemoteKeyListener("MediaFastForward", () => this.moveInTime())
        
        this.$addRemoteKeyListener("MediaTrackPrevious", () => this.moveInTime("start"))
        this.$addRemoteKeyListener("MediaTrackNext", () => this.moveInTime("end"))
        
        this.$addRemoteKeysListener(this.onKeyDown)
    },
    beforeDestroy(){
        document.removeEventListener("keydown", this.onKeyDown)

        this.$deleteRemoteKeyListener ("MediaPlayPause", this.handleRemotePlayPause)
        this.$deleteRemoteKeyListener ("MediaPlay", this.handleRemotePlay)
        this.$deleteRemoteKeyListener ("MediaPause", this.handleRemotePause)

        this.$deleteRemoteKeyListener("MediaRewind", () => this.moveInTime("back"))
        this.$deleteRemoteKeyListener("MediaFastForward", () => this.moveInTime(""))

        this.$deleteRemoteKeyListener("MediaTrackPrevious", () => this.moveInTime("start"))
        this.$deleteRemoteKeyListener("MediaTrackNext", () => this.moveInTime("end"))

        this.$deleteRemoteKeysListener (this.onKeyDown)


    },
    computed:{
        
        videoSrc(){return  this.trailer ? this.show.trailer : this.show.video},
                
        videoPlayer(){return this.$refs.videoPlayer},
        playerButton(){return this.$refs.playerButton},
        playerStatus(){
            const time = secondsToTimeString(this.currentTime, this.videoDuration)
            return `${time}`
        },

        currentSpeedRate(){
            return this.speedRates[this.currentSpeedRateIndex]
        }
    },
    
    methods:{
        goBack(){
            this.goToSmart(this.show)
        },
        togglePlayStatus(){
            this.isPlaying = !this.isPlaying
        },
        percentageToTime(p){
            const percentage = Number(p) || 0
            const time = (this.videoDuration * percentage / 100).toFixed(2)
            return secondsToTimeString(time)
        },
        toggleSpeedRate()
        {
            this.currentSpeedRateIndex = 
            this.currentSpeedRateIndex < this.speedRates.length - 1 ? 
            this.currentSpeedRateIndex + 1 : 0
        },
        onKeyDown(){
            // e.preventDefault()
            if(this.showUI){
                if(UITimerStarted) this.handleTimer(true)
            } else {
                this.showUI = true
                this.handleTimer()
                UITimerStarted = true
            }
        },
        handleTimer(clear=false)
        {

            if(clear) clearTimeout(UITimeout)

            UITimeout = setTimeout(
                ()=>{
                  this.showUI = false
                  UITimerStarted = false
                }, this.UIToggleDelay)
        },
        toggleFullScreen()
        {
            const videoContainer = this.$refs.playerContainer
            
            if(!this.isFullScreen) 
                videoContainer.requestFullscreen()
            else 
                document.exitFullscreen() 
        },
        moveInTime(position="forward")
        {

            if(position==="forward") player.currentTime = Math.round( player.currentTime + 15)
            if(position==="back") player.currentTime = Math.round( player.currentTime - 15)
            if(position==="start") player.currentTime = Math.round( 0)
            if(position==="end") player.currentTime = Math.round(player.duration - 10)

            this.jumpedTime = true
        }, 
        handleRemotePlayPause()
        {
            this.isPlaying = !this.isPlaying
            if(this.showUI){
                if(UITimerStarted) this.handleTimer(true)
            } else {
                this.showUI = true
                this.handleTimer()
                UITimerStarted = true
            }
        },
        handleRemotePlay(){
            if(!this.isPLaying) this.isPlaying = true
        },
        handleRemotePause(){
            this.isPlaying = false
        },
    },
    
    watch: {
        isPlaying(val){
            if(!val)
                this.videoPlayer.pause()
            else 
                this.videoPlayer.play()
        },
        currentSpeedRateIndex(index){
            const videoPlayer = this.$refs.videoPlayer
            let speed = parseFloat(this.speedRates[index].value)
            videoPlayer.playbackRate = speed
        }
    }
 
}
</script>
