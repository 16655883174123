<template>
   <v-banner :class="color">
    <v-avatar
      slot="icon"
      :color="`${color} darken-1`"
      size="40"
      v-if="icon"
    >
      <v-icon
        color="white"
      >
        {{ iconType }}
      </v-icon>
    </v-avatar>

    <slot></slot>
  </v-banner>
</template>

<style lang="scss">
  
</style>

<script>
  export default {
    name: 'Banner',

    mixins: [],

    components: {
      
    },

    data: () => ({
      
    }),

    props: {
      icon: {
        default: true
      },
      success: {
        type: Boolean,
        default: false
      }
    },

    computed: {

      color()
      {
        if( this.success )
          return 'success'
        
        return 'info'
      },
      
      iconType()
      {
        if( typeof this.icon === 'string' )
          return this.icon

        if( this.success )
          return 'mdi-check'

        return 'mdi-information'
      }

    }
  }
</script>
