<template>
  <div>

    <HeaderSpacer/>

    <v-container class="mt-10">

      <h1>
        Condizioni generali di abbonamento
      </h1>

      <v-list class="my-10" 
              color="transparent">
        <v-list-item 
          v-for="(item,i) in list"
          :key="i"
          @click="scrollTo(item.el)">
          {{ (i+1) }}. {{ item.label }}
        </v-list-item>
      </v-list>

      <div v-for="(item,i) in list"
           :key="i"
           :id="item.el"
           class="mb-10">
        <p class="text-h4">  
          {{ item.label }}
        </p>
        <div v-html="item.content"></div>
      </div>

    </v-container>

  </div>
</template>

<script>
import utility from '@/mixins/utility'

import HeaderSpacer from '@/components/UI/HeaderSpacer'

export default {
  
  name: 'Terms',

  mixins: [ utility ],

  components: {
    HeaderSpacer
  },

  data: () => ({
    list: [
      { 
        el: 'subscription', 
        label: 'Abbonamento',
        content: `L'abbonamento a Business+ continuerà fino alla disdetta. Per poter utilizzare il servizio Business+, è necessario un accesso a Internet e un dispositivo compatibile con Business+; bisogna inoltre fornire uno o più Metodi di pagamento. Per "Metodo di pagamento" si intende un metodo di pagamento attuale, valido e accettato che può essere aggiornato periodicamente e includere pagamenti per l’account tramite una terza parte. A meno che si non provveda a disdire l’abbonamento prima della relativa data di fatturazione, saremo autorizzati ad addebitare la quota di abbonamento del ciclo di fatturazione successivo sul Metodo di pagamento prescelto (vedi la sezione "Disdetta" che segue).`
      },
      {
        el: 'payment-methods', 
        label: 'Metodi di pagamento',
        content: `Per utilizzare il servizio Business+ è necessario indicare uno o più Metodi di pagamento. Saremo autorizzati ad addebitare qualsiasi Metodo di pagamento associato ad un account qualora il Metodo di pagamento principale venga rifiutato o non possa essere utilizzato per saldare la quota di abbonamento. L’utente sarà responsabile di eventuali importi insoluti. Qualora un addebito non andasse a buon fine a causa della scadenza del Metodo di pagamento, fondi insufficienti o per qualsiasi altro motivo, e qualora non venisse disdetto l’account, potremmo sospendere l’accesso al servizio fino a quando non avremo addebitato con successo un Metodo di pagamento valido. Per alcuni Metodi di pagamento, l'emittente potrebbe addebitare alcune commissioni, ad esempio per operazioni internazionali o altri oneri associati all'elaborazione del Metodo di pagamento. Gli oneri fiscali locali addebitati potrebbero variare a seconda del Metodo di pagamento utilizzato.`
      },
      { 
        el: 'payment-methods-update', 
        label: 'Aggiornamento dei metodi di pagamento',
        content: `Puoi aggiornare i Metodi di pagamento nella pagina "Account". Potremmo aggiornare i tuoi Metodi di pagamento in base ai dati ricevuti dai gestori dei servizi di pagamento. A seguito di ogni aggiornamento, saremo autorizzati a continuare ad addebitare il/i Metodo/i di pagamento disponibile/i.` 
      },
      { 
        el: 'unsubscribe', 
        label: 'Disdetta',
        content: `Sarà possibile disdire l’abbonamento a Business+ in qualsiasi momento, continuando ad avere accesso al servizio Business+ fino alla fine del periodo di fatturazione. I pagamenti non sono rimborsabili e non verranno concessi rimborsi né crediti per periodi di abbonamento parzialmente utilizzati né per contenuti Business+ non fruiti. Per disdire l'abbonamento visita la pagina "Account" e segui le istruzioni per la disdetta. Se verrà disdetto l’abbonamento, l’account verrà chiuso automaticamente al termine del periodo di fatturazione corrente. Per verificare la data di chiusura dell’account, clicca su "Informazioni per la fatturazione" nella pagina "Account". Se è stata effettuata la registrazione a Business+ usando come Metodo di pagamento l’account con una terza parte e desideri disdire il tuo abbonamento a Business+, si dovrà disdire l'abbonamento tramite tale terza parte, ad esempio accedendo alle impostazioni del tuo account con tale terza parte e disattivando l'opzione di rinnovo automatico o annullando il servizio Business+ attraverso tale terza parte.`
      },
      {
        el: 'tos-changes', 
        label: 'Modifiche al prezzo e ai piani di abbonamento',
        content: `Di tanto in tanto potremmo modificare i nostri piani di abbonamento e il prezzo del nostro servizio; tuttavia, qualsiasi modifica del prezzo o dei piani di abbonamento avrà decorrenza non prima di 30 giorni dalla data in cui invieremo la relativa comunicazione.`
      },
      {
        el: 'service', 
        label: 'Servizio',
        content: `<p>Per abbonarsi al servizio Business+ è necessario avere almeno 18 anni. I soggetti minorenni possono usufruire del servizio esclusivamente sotto la supervisione di un adulto. Il servizio Business+ e qualsiasi contenuto accessibile attraverso il servizio sono destinati esclusivamente ad un uso personale e non commerciale. Per la durata dell’abbonamento Business+, concediamo un diritto limitato, non esclusivo e non trasferibile di accedere al servizio Business+ e ai contenuti Business+. Salvo quanto sopra, nessun altro diritto, titolo o interesse ti sarà trasferito. Accetti di non utilizzare il servizio per proiezioni pubbliche.</p>
          <p>Accetti di non conservare, riprodurre, distribuire, modificare, visualizzare, mostrare, pubblicare, concedere in licenza, creare opere derivate da, offrire in vendita o utilizzare (fatto salvo ove espressamente autorizzato nelle Condizioni di utilizzo) contenuti e informazioni sul servizio Business+ contenuti o ottenuti da o tramite il servizio Business+. Accetti inoltre di non: aggirare, rimuovere, alterare, disattivare, degradare o bloccare le protezioni dei contenuti nel servizio Business+; utilizzare robot, spider, scraper o altri strumenti automatici per accedere al servizio Business+; decompilare, sottoporre a reverse engineering (decodificare) o disassemblare qualsiasi software o altro prodotto o processo accessibile tramite il servizio Business+; inserire qualsiasi codice o prodotto o manipolare il contenuto del servizio Business+ in qualsiasi modo; o utilizzare qualsiasi metodo di data mining o di raccolta o estrazione di dati. Accetti inoltre di non caricare, postare, inviare tramite posta elettronica o altrimenti inviare o trasmettere qualsiasi materiale progettato per interrompere, distruggere o limitare le funzionalità di qualsiasi software per computer o hardware o terminale di telecomunicazione associato al servizio Business+, compresi virus e qualsiasi altro codice, file o programma per computer. Avremo la facoltà di limitare o interrompere l'utilizzo del nostro servizio da parte tua qualora tu violassi le presenti Condizioni di utilizzo o utilizzassi il nostro servizio in maniera illecita o fraudolenta. 
</p>`
      }
    ]
  }),

  computed: {},

  methods:
  {
    
  },

  mounted()
  {

  }

}
</script>
