<template>
    <div class="uvp-container" ref="videoContainer" >
        <div class="spinner-container" v-if="isLoading">
            <v-progress-circular   indeterminate color="primary">
            </v-progress-circular>
        </div>
        <div class="uvp-video-container">
            <video :src="videoSrc" class="vjs-tech" 
                preload="true"
                :poster="show.bg"
                autobuffer="true"
                playsinline="playsinline" 
                webkit-playsinline="true" 
                x5-playsinline="true" 
                x5-video-player-type="h5" 
                x5-video-player-fullscreen="false" 
                id="vjs_video_531_html5_api" 
                ref="videoPlayer"></video>
        </div>
        <div class="uvp-ui" :class="{'uvp-ui-fullscreen': isFullScreen}" v-focus-section>
            <div class="uvp-row uvp-title" v-focus-section ref="uiTitleBar">
                <v-btn text @click="goBack" v-focus>
                    <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
                <v-btn text>
                    {{show.title}}
                </v-btn>
            </div>
            
            <div class="uvp-row" ref="uiBottomBar">
                <v-btn text @click.prevent="togglePlayStatus" v-focus>
                    <v-icon v-if="!isPlaying">mdi-play</v-icon>
                    <v-icon v-else>mdi-pause</v-icon>
                </v-btn>
                <v-slider class="uvp-grow"
                        min="0"
                        max="100"
                        @change="handleTimeChange"
                        :value="currentPercentage"
                        thumb-label>
                            <template v-slot:thumb-label>
                                {{percentageToTime(currentPercentage)}}
                            </template>
                        </v-slider>
                <div class="uvp-button">
                    {{playerStatus}}
                </div>
                <v-btn text v-focus @click.prevent="toggleSpeedRate">
                    {{currentSpeedRate.key}}
                </v-btn>
                <!-- <v-btn text @click.prevent="toggleFullScreen" v-focus>
                    <v-icon v-if="!isFullScreen">mdi-fullscreen</v-icon>
                    <v-icon v-else>mdi-fullscreen-exit</v-icon>
                </v-btn> -->
            </div>
        </div>
    </div>
</template>
<style lang="scss">

.v-input__control{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 0;
            display: flex;

            & > .v-input__slot{
                margin-bottom: 0;
            }

            & > .v-messages{
                display: none;
            }
}
</style>
<style lang="scss" scoped>



body{
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.uvp-container{
    position: absolute;
    z-index: 100;
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;



    & > div{
        flex-grow: 1;
        flex-shrink: 0;
    }

    & .uvp-ui{
        position: fixed;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    & .uvp-row{
        display: flex;
        gap: 10px;
        padding: 10px;
        background: rgb(24, 32, 49);
        flex-shrink: 0;
        z-index: 1000;
        height: 50px;
        align-items: center;
    }

    & .uvp-video-container{
        flex: 1;
        height: 100%;
        widows: 100%;
        & video{
            height: 100%;
            width: 100%;
        }
    }


    & .uvp-button{
        flex-basis: 50px;
    }

    & .uvp-grow{
        flex-grow: 1;
    }
}

.hide-panel{
    display: none !important;
}


.spinner-container{

	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}


</style>
<script>
import { defineComponent } from '@vue/composition-api'
import utility from '@/mixins/utility'

const prefixZero = val => String(val).length === 1 ? `0${val}` : val

const timeToHMS = seconds => ({
    h: prefixZero(Math.floor(seconds / 60 / 60)),
    m: prefixZero(Math.floor(seconds / 60)),
    s: prefixZero(seconds % 60)
})

function secondsToTimeString(time, duration=0)
{
    const seconds = duration > 0 ? Math.round(duration-time) : Math.round(time)
    
    const {h,m,s} = timeToHMS(seconds)
    
    return `${h}:${m}:${s}`
}


export default defineComponent({
    mixins: [utility],      
    data: () => ({
        src: null,
        isLoading: true,
        isPlaying: false,
        isFullScreen: false,
        videoDuration: 0,
        currentTime: 0,
        currentPercentage: 0,
        currentSpeedRateIndex: 0,
        fullscreenHideTime: 3000,
        speedRates:[{
            key: "1X",
            value: 1
        },{
            key: "1.5X",
            value: 1.5
        },{
            key: "2X",
            value: 2
        },{
            key: "0.5X",
            value: 0.5
        },{
            key: "0.75X",
            value: 0.75
        }]
    }),
    
    props: {
        show: {
            type: Object,
            default: ()=>({})
        }, trailer: {
            type: Boolean,
            default: false
        }
    },
    mounted()
    {
        const videoPlayer = this.$refs.videoPlayer
        this.toggleFullScreen()
        if(videoPlayer)
        {
            videoPlayer.oncanplay = () => {
                this.videoDuration = videoPlayer.duration
                let speed = parseFloat(this.currentSpeedRate)
                this.isLoading = false
                
                if( !isNaN(speed) )
                    videoPlayer.playbackRate = speed
                
                videoPlayer.play()
                this.isPlaying = true
            }

            videoPlayer.ontimeupdate = () => {
                this.currentTime = videoPlayer.currentTime;
                this.currentPercentage = (this.currentTime / this.videoDuration * 100).toFixed(2)
            }

            videoPlayer.waiting = () => {
                this.isLoading = true
                this.isPlaying = false
            }
        }
        
        document.addEventListener("fullscreenchange", this.onFullScreenChange)
        
        document.addEventListener("keydown", this.onKeyDown)
    },
    
    computed:
    {
        
        videoSrc()
        {
            return  this.trailer ? this.show.trailer : this.show.video
        },
        
        playButtonValue()
        {
            return this.isPlaying ? "pause" : "play"
        },
        
        videoPlayer()
        {
            return this.$refs.videoPlayer
        },
        
        playerStatus()
        {
            const time = secondsToTimeString(this.currentTime, this.videoDuration)
            
            return `${time}`
        },
        
        currentSpeedRate()
        {
            return this.speedRates[this.currentSpeedRateIndex]
        }
        
    },
    
    methods:
    {
        
        handleTimeChange(e)
        {
            const t = e * this.videoDuration / 100
            
            this.videoPlayer.currentTime = t
            //console.log(t)
        },
        
        onFullScreenChange()
        {
            this.isFullScreen = !this.isFullScreen
        },
        
        onKeyDown(e)
        {
            e.preventDefault()
            
            const bottomBar = this.$refs.uiBottomBar
            
            if( this.isFullScreen && 
                bottomBar.classList.contains("hide-panel"))
            {
                this.hideUi(false)
                
                setTimeout(() => this.isFullScreen ? this.hideUi() : null, this.fullscreenHideTime)
            }
        },

        goBack()
        {
            this.goToSmart(this.show)
        },
        
        togglePlayStatus()
        {
            if(this.isPlaying)
                this.$refs.videoPlayer.pause()
            
            else 
                this.$refs.videoPlayer.play()
            
            this.isPlaying = !this.isPlaying
        },
        
        toggleFullScreen()
        {
            const videoContainer = this.$refs.videoContainer
            
            if(!this.isFullScreen) 
                videoContainer.requestFullscreen()
            
            else 
                document.exitFullscreen() 
            
            // if(videoContainer.exitFullscreen) videoContainer.exitFullscreen()
            // else if(videoContainer.webkitExitFullscreen)videoContainer.webkitExitFullscreen()
            // else if(videoContainer.mozExitFullscreen) videoContainer.mozExitFullscreen()
                
        },
        
        toggleFullScreenUi()
        {
            const uiPanel = this.$refs.uiBottomBar
        
            const isHidden = uiPanel.classList.contains("hide-panel")
        
            if(this.isFullScreen && !isHidden)
                this.hideUi(false)
        },
        
        percentageToTime(p)
        {
            const percentage = Number(p) || 0
            
            const time = (this.videoDuration * percentage / 100).toFixed(2)
            return secondsToTimeString(time)
        },
        
        toggleSpeedRate()
        {
            this.currentSpeedRateIndex = 
                this.currentSpeedRateIndex < this.speedRates.length - 1 ? 
                this.currentSpeedRateIndex + 1 : 0
        },
        
        hideUi(val=true)
        {
            const uiPanel = this.$refs.uiBottomBar
            const titlePanel = this.$refs.uiTitleBar
            if(val){
                uiPanel.classList.add("hide-panel")
                titlePanel.classList.add("hide-panel")
            } else{
                uiPanel.classList.remove("hide-panel")
                titlePanel.classList.remove("hide-panel")
            }

        }
    },
    
    watch: {
        
        isFullScreen(isFullScreen)
        {
            if(isFullScreen)
                setTimeout(this.hideUi, this.fullscreenHideTime)
            
            else
                this.hideUi(false)
        },
        
        currentSpeedRateIndex(index)
        {
            const videoPlayer = this.$refs.videoPlayer
            
            let speed = parseFloat(this.speedRates[index].value)

            videoPlayer.playbackRate = speed
        }
    }
})
</script>
