<template>
  <v-container v-focus-section>

    <h1 class="mt-7">{{ collection.name }}</h1>

    <v-row class="mt-7">
      <v-col v-for="(show,i) in shows" 
             :key="i"
             cols="3">
        <ShowCard :show="show"/>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import API from '@/api'

import utility from '@/mixins/utility'
import slugMixin from '@/mixins/slug'

import ShowCard from '@/components/show/Card'

import { mapMutations } from 'vuex'

export default {
  
  name: 'Collection',

  mixins: [ utility, slugMixin ],

  components: {
    ShowCard
  },

  data: () => ({
     collection: {}
  }),

  computed: {
    shows()
    {
      return this.collection.shows
    }
  },

  methods:
  {
    ...mapMutations({
      setLoading: 'ui/setLoading'
    }),

    load()
    {
      this.setLoading(true)

      API.collections.one(this.slug)
                     .then((res)=>{
                      
                      this.collection = this.mapModel(res.data.data, 'collection')
                      
                      this.setLoading(false)

                     })
    }
  },

  mounted()
  {
    this.load()
  }

}
</script>
