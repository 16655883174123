<template>
   <div class="header-spacer"
        :style="css"></div>
</template>

<style type="text/css" scoped>
   .header-spacer {
    
   }
</style>

<script>
  export default {
    name: 'HeaderSpacer',

    data: () => ({
      
    }),

    props: {
      height: {
        type: Number,
        default: 78
      }
    },

    computed:
    {
      css()
      {
        return {
          height: `${this.height}px`
        }
      }
    }
  }
</script>
