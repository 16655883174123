<template>
   <!-- owl-carousel Videos Section-1 Start -->
   <section class="py-5" v-focus-section>

      <div class="container">
         <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-6">
               <h4 class="gen-heading-title" v-if="title">
                 {{ title }}
               </h4>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 d-none d-md-inline-block"
                 v-if="buttonLabel">
               <div class="gen-movie-action">
                  <div class="gen-btn-container text-right">
                    <v-btn color="primary" tile x-large 
                      @click="onButtonClick"
                      v-focus>
                      {{ buttonLabel }}
                    </v-btn>
                  </div>
               </div>
            </div>
         </div>
         <div class="row mt-3">
            <div class="col-12">

              <!-- loading UI -->
              <div class="text-center py-5"
                   v-if="loading">
                <v-progress-circular
                  indeterminate
                  :size="45"
                  color="primary"
                ></v-progress-circular>
              </div>

               <!-- carousel -->
               <div class="gen-style-2" 
                    v-else>
                
                <carousel :dots="false" 
                          :nav="isMobile ? false : nav" 
                          :items="responsiveCols" 
                          :autoplay="false" 
                          :loop="false" 
                          :margin="30"
                          :stagePadding="30">
                
                  <div class="item"
                       v-for="(item,i) in items"
                       :key="i">
                    
                    <ShowCard :show="item"
                              :details="details"
                              :layout="layout"/>
                 
                 </div>
                
                </carousel>
               </div>
            </div>
         </div>
      </div>
   </section>
   <!-- owl-carousel Videos Section-1 End -->
</template>

<style lang="scss">
   .owl-stage {
    padding-top: 30px;
    padding-bottom: 30px;
   }
</style>

<script>
  import utility from '@/mixins/utility'

  // @see https://github.com/s950329/vue-owl-carousel
  import carousel from 'vue-owl-carousel'

  import ShowCard from '@/components/show/Card'

  export default {
    name: 'SlideGroup',

    mixins: [utility],

    components: {
      carousel,
      ShowCard
    },

    data: () => ({
      
    }),

    props: {
      buttonLabel: {
        type: String,
        default: ''
      },
      cols: {
        type: Number,
        default: 4
      },
      details: {
        type: Boolean,
        default: true
      },
      items: {
         type: Array,
         default: () => []
      },
      layout: {
        type: String,
        default: 'cover'
      },
      loading: {
        type: Boolean,
        default: false
      },
      nav: {
        type: Boolean,
        default: false
      },
      title: {
        type: String,
        default: ''
      }
    },

    computed:
    {
      responsiveCols()
      {
        if( this.isSmartphone )
          return 2

        return this.cols
      }
    },

    methods:
    {
      onButtonClick()
      {
        this.$emit('button')
      }
    }
  }
</script>
