const axios = require('axios')

import Vue from 'vue'
import App from './App.vue'
import i18n from './plugins/vue-i18n'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

import SETTINGS from '@/plugins/settings'

Vue.config.productionTip = false

import 'material-design-icons-iconfont/dist/material-design-icons.css'

// Vuelidate.
import VueCompositionAPI from '@vue/composition-api'
import Vuelidate from 'vuelidate'

//Vue.use(VueCompositionAPI)
Vue.use(Vuelidate)

/**
 * Vue Clipboard
 */
import VueClipboard from 'vue-clipboard2'

Vue.use(VueClipboard)

/**
 * Vue Social Sharing
 * @see https://github.com/nicolasbeauvais/vue-social-sharing
 */
import VueSocialSharing from 'vue-social-sharing'

Vue.use(VueSocialSharing)

/**
 * Google Analytics
 * @see https://matteo-gabriele.gitbook.io/vue-gtag/v/master/custom-installation
 */

import VueGtag from "vue-gtag"

Vue.use(VueGtag, {
  config: { id: SETTINGS.GA.id }
}, router)

/**
 * SEO
 * @see https://vue-meta.nuxtjs.org/
 * @see https://www.epiloge.com/how-to-add-dynamic-meta-tags-to-your-vuejs-app-for-google-seo-0fa058
 */
import VueMeta from 'vue-meta'

Vue.use(VueMeta)

/**
 * Spatial navigation for TVs.
 * @see https://github.com/spacerefugee/vue-js-spatial-navigation
 */
import vjsn from "vue-js-spatial-navigation"

// const spatialConfig = {
//   scrollOptions: { 
//     straightOnly: true,
//     rememberSource:true,
//     enterTo: "last-focused",
//     behavior: "smooth",
//     restrict: "self-first",
//     block: "center" }
// }

const spatialConfig = {

    straightOnly: true,
    rememberSource:true,
    straightOverlapTreshold: 0.5,
    // enterTo: "last-focused",
    behavior: "smooth",
    restrict: "self-first",
    navigableFilter: null,
    scrollOptions: {
      behavior: "smooth",
      block: "center",
      scrollIntoView:true
    }

}

Vue.use(vjsn, spatialConfig)


import RemoteKeysHandler from '@/plugins/remoteKeysHandler'

Vue.use(new RemoteKeysHandler())


new Vue({
  i18n,
  router,
  store,
  vuetify,
  VueCompositionAPI,
  mixins:[
    RemoteKeysHandler.instance.mixin
  ],
  render: h => h(App),
  created () {
    /**
     * Get local user data.
     */
    const userInfo = localStorage.getItem('user')
    if (userInfo) {
      const userData = JSON.parse(userInfo)
      this.$store.commit('setUserData', userData)
    }
    /**
     * Get user favourites.
     */
    const userFavourites = localStorage.getItem('favourites')
    if (userFavourites) {
      const favourites = JSON.parse(userFavourites)
      this.$store.commit('setUserFavourites', favourites)
    }
    else {
      this.$store.dispatch('loadFavourites')
    }
    /**
     * If APIs get 401 logout user.
     */
    axios.interceptors.response.use(
      response => response,
      error => {
        if (error.response.status === 401) {
          this.$store.dispatch('logout')
        }
        return Promise.reject(error)
      }
    )
  }
}).$mount('#app')
