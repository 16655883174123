import moment from 'moment'

class Subscription {

  #data = {}

  #plan = null;

  #price = null;

  constructor(collection={})
  {  
    this.#data = collection

    if( collection.plan )
      this.#plan = collection.plan

    if( collection.price )
      this.#price = collection.price
  }

  /**
   * Property getters.
   */
  
  get id()
  {
    return this.#data.id
  }

  get endsAt()
  {
    return this.#data.ends_at ? moment(this.#data.ends_at) : null
  }

  get name()
  {
    return this.#data.name
  }

  get plan()
  {
    return this.#plan
  }

  get price()
  {
    return this.#price
  }
  

  /**
   * Computed properties.
   */
  
  get hasEnd()
  {
    return this.#data.ends_at !== null
  }

  get hasEnded()
  {
    return this.endsAt ? this.endsAt.isBefore(moment()) : false
  }

  get isEnding()
  {
    return this.endsAt ? this.endsAt.isAfter(moment()) : false
  }

  get status()
  {
    if( this.hasEnd )
      return 'cancellato'

    return 'attivo'
  }
  
}

export default Subscription