<template>
  <div>

    <v-tabs background-color="transparent"
            v-model="tab"
            align-with-title
            :class="isMobile ? 'mt-10' : ''"
            v-focus-section>
      
      <v-tab v-for="(item,i) in tabs"
             :key="i"
             v-focus>
        <h3>{{ item.label }}</h3>
      </v-tab>

    </v-tabs>

    <v-tabs-items v-model="tab"
                  class="transparent mb-15">
      
      <v-tab-item v-for="(item,i) in tabs"
                  :key="i">
        <component :is="item.component" :show="show"/>
      </v-tab-item>

    </v-tabs-items>

  </div>
</template>

<style lang="scss" scoped>  
  
</style>

<script>
  import utility from '@/mixins/utility'

  import TabDetails from '@/components/show/Tabs/Details'
  import TabEpisodes from '@/components/show/Tabs/Episodes'

  export default {
    name: 'ShowTabs',

    mixins: [ utility ],

    components: {
      TabDetails,
      TabEpisodes
    },

    data: () => ({
      tab: 0
    }),

    props: {
      show:
      {
        default: null
      }
    },

    computed:
    {
      hasSeries()
      {
        return this.series.length > 0
      },
      series()
      {
        return this.show.series
      },
      tabs()
      {
        let tabs = []

        if(this.hasSeries)
          tabs.push({ label:'Episodi', component:'TabEpisodes' })

        if( this.show.hasDetails )
          tabs.push({ label:'Dettagli', component:'TabDetails' })

        return tabs
      }
    },

    methods:
    {
      
    }

  }
</script>
