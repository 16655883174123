<template>
  <div>

    <SearchBar :search.sync="theSearch"
               :loading="isLoading"
               readonly/>

    <v-row class="px-10">

      <v-col cols="3"
             class="pr-10 pt-10">
        <Keyboard @key="onKeyboard"
                  @remove="onKeyboardRemove"/>
      </v-col>

      <v-col cols="9">

        <!-- no results message -->
        <v-alert type="info" 
                 v-if="noResults"
                 class="text-h5 mt-7 mb-12">
          Non ci sono risultati per questa ricerca.
        </v-alert>

        <!-- heading -->
        <h1 class="mt-7">
          {{ resultsHeading }}
        </h1>

        <!-- results -->
        <v-row class="mt-7" v-if="shows.length && theSearch">
          <v-col v-for="(show,i) in shows" 
                 :key="i"
                 cols="3">
            <ShowCard :show="show"/>
          </v-col>
        </v-row>

        <!-- default shows -->
        <v-row class="mt-7" v-else>
          <v-col v-for="(show,i) in defaultShows" 
                 :key="i"
                 :cols="isMobile ? 6 : 3">
            <ShowCard :show="show"/>
          </v-col>
        </v-row>

      </v-col>

    </v-row>

  </div>
</template>

<script>
import API from '@/api'

import liveTyping from '@/mixins/liveTyping'
import utility from '@/mixins/utility'

import SearchBar from '@/components/UI/SearchBar'
import Keyboard from '@/components/UI/Keyboard'
import ShowCard from '@/components/show/Card'

import { mapMutations } from 'vuex'

export default {
  
  name: 'Search',

  mixins: [ liveTyping, utility ],

  components: {
    SearchBar,
    Keyboard,
    ShowCard
  },

  data: () => ({
    defaultShows: [],
     isLoading: false,
     theSearch: '',
     shows: []
  }),

  computed: {
    noResults()
    {
      return (this.theSearch && !this.shows.length)
    },
    resultsHeading()
    {
      if( this.noResults )
        return 'Potrebbe interessarti...'

      else if( this.theSearch )
        return 'Risultati della ricerca'

      return 'Guarda online'
    }
  },

  methods:
  {
    ...mapMutations({
      setLoading: 'ui/setLoading'
    }),

    doSearch(search)
    {
      this.isLoading = true

      // utilizzo il livetyping prima di inviare la richiesta API
      let resolve = () => {

        // Google Analytics.
        this.$gtag.event('search', {
          'event_category': 'search',
          'event_label': 'search',
          'value': search
        })

        API.shows.list({ 'filter[title]':search })
                 .then((res)=>{

                  this.shows = this.mapModels(res.data.data)

                  this.isLoading = false

                 })
          
      }

      this.liveType(resolve)

    },

    loadDefaults()
    {
      this.setLoading(true)

      API.shows.latest({ 'filter[not_episodes]':1 })
               .then((res)=>{

                this.defaultShows = this.mapModels(res.data.data)

                this.setLoading(false)

               })
    },

    onKeyboard(key)
    {
      this.theSearch += key
    },

    onKeyboardRemove()
    {
      let index = (this.theSearch.length - 1), 
          search = this.theSearch.substr(0, index)

      this.theSearch = search
    }

  },

  mounted()
  {
    this.loadDefaults()

    //Rimuovo overflow per visualizzare la pagina in maniera corretta
    document.body.style.overflow = "hidden"
    // this.$SpatialNavigation.
  },
  destroyed()
  {
    //Imposto l'overflow alle impostazioni iniziali una volta che sono uscito dalla pagina
    document.body.style.overflow = "initial"
  },
  watch:
  {
    theSearch(newValue)
    {
      this.doSearch(newValue)
    }
  }

}
</script>
