import _ from 'lodash'

import utility from '@/mixins/utility'

const Demo = {
  methods: {
    getCast()
    {
      return [
          { name: 'Joe Casini', slug: 'joe-casini' },
          { name: 'Davide Ippolito', slug: 'davide-ippolito' },
          { name: 'Claudio Brachino', slug: 'claudio-brachino' }
       ]
    },
    getEpisodes()
    {
       return this.getShows()
    },
    getGenres()
    {
      return [
        { title:'Documentario', slug:'documentario' },
        { title:'Podcast', slug:'podcast' },
        { title:'Film', slug:'film' }
      ]
    },
    getSeries()
    {
       return [
          {
            title: 'Serie 1',
            episodes: this.getEpisodes()
          },
          {
            title: 'Serie 2',
            episodes: this.getEpisodes()
          }
       ]
    },
    getShows()
    {
      const genres = this.getGenres(),
            cast = this.getCast()

      const shows = [
          { 
            title: "Reputazione a rischio", 
            slug: "reputazione-a-rischio",
            tagline: "NOVITÀ",
            excerpt: "A short description here.", 
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur suscipit, ante id mattis porttitor, ante urna feugiat turpis, vitae dapibus magna odio accumsan enim. Aenean tincidunt massa non aliquet dictum. Aliquam accumsan tempus egestas. Duis ornare ipsum velit, ut gravida augue commodo eget. Ut iaculis neque auctor molestie semper. Donec mollis magna nisi.",
            year: 2020,
            duration: '90 minuti',
            genres: _.chain(genres).shuffle().take(2).value(),
            cast: _.chain(cast).shuffle().take(2).value(),
            video: 'h234h-h23uj-43h2u-jt4i5'
          },
          { 
            title: "Casa Brachino", 
            slug: "casa-brachino",
            tagline: "NUOVI EPISODI",
            excerpt: "A short description here.", 
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur suscipit, ante id mattis porttitor, ante urna feugiat turpis, vitae dapibus magna odio accumsan enim. Aenean tincidunt massa non aliquet dictum. Aliquam accumsan tempus egestas. Duis ornare ipsum velit, ut gravida augue commodo eget. Ut iaculis neque auctor molestie semper. Donec mollis magna nisi.",
            year: 2021,
            duration: '20 minuti',
            genres: _.chain(genres).shuffle().take(2).value(),
            cast: _.chain(cast).shuffle().take(2).value(),
            video: 'h23ur-hk4jh-hj4i2-hf32u'
          }
       ]

      return utility.methods.mapModels(shows)
    }
  }
}

export default Demo