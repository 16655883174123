<template>
  <div>

    <HeaderSpacer/>

    <v-container class="mt-10 text-center">

      <h1>
        Centro Assistenza
      </h1>

      <p>
        Qualcosa non va? Faccelo sapere compilando il form qui sotto.
      </p>

      <Loading :status="isLoading"/>

      <Banner success
              style="max-width:350px"
              class="mt-10 mx-auto"
              v-if="isDone">
        Abbiamo ricevuto la tua richiesta
      </Banner>

      <v-form class="help-form mx-auto mt-10"
              v-else>
        <v-row>
          <!-- firstname -->
          <v-col :cols="isMobile ? 12 : 6">
            <v-text-field
              label="Il tuo nome"
              v-model="form.firstname"
              required
              outlined
              :autofocus="true"
              class="transparent"
              :error="inputErrorClass('firstname')"
              @keyup.enter="submit"
            ></v-text-field>
          </v-col>
          <!-- lastname -->
          <v-col :cols="isMobile ? 12 : 6">
            <v-text-field
              label="Il tuo cognome"
              v-model="form.lastname"
              required
              outlined
              class="transparent"
              :error="inputErrorClass('lastname')"
              @keyup.enter="submit"
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- email -->
        <v-row>
          <v-col>
            <v-text-field
              label="Il tuo indirizzo email"
              v-model="form.email"
              required
              outlined
              class="transparent"
              :error="inputErrorClass('email')"
              @keyup.enter="submit"
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- il tuo messaggio -->
        <v-row>
          <v-col>
            <v-textarea
              label="Il tuo messaggio"
              v-model="form.content"
              required
              outlined
              class="transparent"
              :error="inputErrorClass('content')"
            ></v-textarea>
          </v-col>
        </v-row>
        <!-- submit -->
        <v-row>
          <v-col>
            <v-btn tile 
                   color="primary"
                   x-large
                   :disabled="!canSubmit"
                   @click="submit">
              Invia richiesta
            </v-btn>
          </v-col>
        </v-row>
      </v-form>

    </v-container>

    <div class="py-10"><!-- empty space --></div>

  </div>
</template>

<style lang="scss">
  .help-form {

  }

  @media (min-width: 767px) {
    .help-form {
      max-width: 50vw;
    }
  }
</style>

<script>
import Banner from '@/components/UI/Banner'
import HeaderSpacer from '@/components/UI/HeaderSpacer'
import Loading from '@/components/UI/Loading'

import vuelidate from '@/mixins/vuelidate'

import { required, email } from '@vuelidate/validators'

import { mapMutations } from 'vuex'

import axios from 'axios'

import SETTINGS from '@/plugins/settings'

export default {
  
  name: 'Help',

  mixins: [ vuelidate ],

  components: {
    Banner,
    HeaderSpacer,
    Loading
  },

  data: () => ({
    form: {
      firstname: null,
      lastname: null,
      email: null,
      content: null
    },
    isDone: false,
    isLoading: false,
    status: false,
  }),

  computed: {},

  validations()
  {
    return {
      form: {
        firstname: {
          required
        },
        lastname: { 
          required
        },
        email: { 
          required,
          email
        },
        content: { 
          required
        },
      }
    }
  },

  methods:
  {
    ...mapMutations({
      'growl': 'ui/growl'
    }),

    reset()
    {
      this.form.firstname = null
      this.form.lastname = null
      this.form.email = null
      this.form.content = null
    },

    submit()
    {
      if( !this.canSubmit )
        return

      const url = SETTINGS.FORM.help

      let data = this.form

      this.isLoading = true

      axios.post(url, data)
           .then(()=>{

            this.growl({
              content: 'La tua richiesta è stata inviata.',
              color: 'success'
            })

            this.isDone = true
            
            this.reset()

           })
           .catch((err)=>{
            console.error(err)
           })
           .finally(()=>{
            this.isLoading = false
           })
    }
  },

  mounted()
  {

  }

}
</script>
