import moment from 'moment'

class PaymentMethod {

  #data = {}

  constructor(collection={})
  {  
    this.#data = collection
  }

  /**
   * Property getters.
   */
  
  get id()
  {
    return this.#data.id
  }

  get type()
  {
    return this.#data.type
  }

  get card()
  {
    return this.#data.card
  }

  get billing()
  {
    return this.#data.billing_details
  }

  /**
   * Computed properties.
   */
  
  get isCard()
  {
    return this.type === 'card'
  }

  get isDefault()
  {
    return this.#data.default
  }

  get cardNumber()
  {
    return `**** **** **** ${this.card.last4}`
  }

  get expiration()
  {
    if(!this.card)
      return null

    let month = this.card.exp_month,
        year = this.card.exp_year, 
        m = moment().month(month).year(year)

    return m
  }
  

}

export default PaymentMethod