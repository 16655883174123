import Vue from 'vue'
import VueI18n from 'vue-i18n'

import en from './en'
import it from './it'

const messages = {
  en,
  it
}

Vue.use(VueI18n)

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: 'it', // set locale
  messages, // set locale messages
})

export default i18n