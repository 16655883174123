<template>
  <div v-focus-section>

    <v-container class="mt-10 mb-10">

      <h1 class="mt-7">La tua lista</h1>

      <v-row class="mt-7" v-if="!shows.length">
        <v-col>
          <v-alert type="info">
            Non hai ancora aggiunto nessun filmato alla tua lista.
          </v-alert>
        </v-col>
      </v-row>

      <v-row class="mt-7" v-else>
        <v-col v-for="(show,i) in shows" 
               :key="i"
               cols="3">
          <ShowCard :show="show"/>
        </v-col>
      </v-row>

    </v-container>

    <Loading :status="!shows.length"/>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import Loading from '@/components/UI/Loading'
import ShowCard from '@/components/show/Card'

export default {
  
  name: 'Watchlist',

  mixins: [],

  components: {
    Loading,
    ShowCard
  },

  data: () => ({

  }),

  computed: {
    ...mapGetters({
      shows: 'favourites',
    })
  },

  methods:
  {
    
  },

  mounted() {

  },

  watch:
  {
    
  }

}
</script>
