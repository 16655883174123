<template>
  <div>

    <Carousel :items="featuredShows" 
              v-if="featuredShows.length"
              class="mt-10"
              :autoplay="false"/>

    <SlideGroup :items="latestShows" 
                title="Ultime uscite"
                :loading="loading.latestShows"
                v-if="!loading.latestShows && latestShows.length"/>

    <SlideGroup :items="shows" 
                button-label="Vedi tutti" 
                @button="goTo('watch')"
                title="Potrebbe interessarti..."
                :loading="loading.shows"
                v-if="!loading.shows && shows.length"/>

    <Collection v-for="collection in collections"
                :key="collection.id"
                :collection="collection"/>

  </div>
</template>

<script>
import _ from 'lodash'
import API from '@/api'

import SETTINGS from '@/plugins/settings'

import utility from '@/mixins/utility'

import Collection from '@/components/collection/Collection'

import Carousel from '@/components/slideshows/Carousel'
import SlideGroup from '@/components/slideshows/SlideGroup'

import { mapMutations } from 'vuex'

export default {
  
  name: 'Home',

  mixins: [ utility ],

  components: {
    Carousel,
    Collection,
    SlideGroup
  },

  data: () => ({
    collections: [],
    featuredShows: [],
    latestShows: [],
    loading: {
      featured: false,
      latestShows: false,
      shows: false
    },
    connectionError: false,
    shows: []
  }),

  computed:
  {
    isLoading()
    {
      let isLoading = _.filter(this.loading)

      return isLoading.length > 0
    }
  },

  methods: 
  {
    ...mapMutations({
      setLoading: 'ui/setLoading'
    }),

    load()
    {
      this.loadFeaturedShows()

      this.loadShows()

      this.loadLatestShows()
      
      this.loadCollections()
    },

    loadFeaturedShows()
    {
      const query = { 
              'filter[featured]': true, 
              'filter[with_slideshow]': true 
            }

      this.loading.featured = true

      API.shows.list(query)
               .then(({ data })=>{

                let shows = data.data

                this.featuredShows = this.mapModels(shows)

                this.loading.featured = false

               })
              .catch(this.handleError)
    },

    loadShows()
    {
      this.loading.shows = true

      API.shows.list({ 'filter[not_episodes]':true })
              .then((res)=>{
                
                this.shows = this.mapModels(res.data.data)

                this.loading.shows = false

              })
              .catch(this.handleError)
    },

    loadLatestShows()
    {
      this.loading.latestShows = true

      const query = { 
        'filter[not_episodes]' : true,
        'filter[is_published]' : true 
      }

      API.shows.latest(query)
               .then((res)=>{

                let ordered = _.orderBy(res.data.data, 'updated_at', 'desc'),
                    shows = this.mapModels(ordered)
              
                this.latestShows = shows

                this.loading.latestShows = false

              })
              .catch(this.handleError)
    },

    loadCollections()
    {
      API.collections.list()
                     .then(({ data })=>{

                      let collections = this.mapModels(data.data, 'collection')

                      this.collections = collections

                     })
                    .catch(this.handleError)
    },
    handleError(){
      this.loading.latestShows = false
      this.loading.shows = false
      this.connectionError = true
    }
  },

  mounted()
  {
    this.load()
  },

  metaInfo() {
    return {
      titleTemplate: SETTINGS.APP_NAME,
    }
  },

  watch:
  {
    loading:
    {
      deep: true,
      handler()
      {
        if(this.isLoading)
          this.setLoading(true)

        else
          this.setLoading(false)
      }
    },
    connectionError(val)
    {
      if(val) {
        this.setLoading(false)
        this.$router.push("/error")
      }
    }
  }
  
}
</script>
