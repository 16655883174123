<template>
  <v-app>

    <!-- <SpatialNavigationHelper/> -->

    <router-view />

    <Growler />

    <!-- <ProfileDialog ref="profileDialog"/> -->

    <SpatialNavigation />

    <Loading :status="isLoading" :opacity="1" class="global-loading" />

  </v-app>
</template>

<style lang="scss">
@import './skin/css/bootstrap.min.css';
@import './skin/css/style.css';
@import './skin/css/responsive.css';

[v-cloak] {
  display: none
}

.dark-blue {
  background-color: rgb(24, 32, 49) !important;
}

div.theme--dark.v-application {
  background-color: transparent;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: none;
}

.pointer {
  cursor: pointer;
}

.v-application *.special-font {
  font-family: var(--title-fonts) !important;
}

.v-application .mt-15 {
  margin-top: 60px !important;
}

.v-application .mt-20 {
  margin-top: 80px !important;
}

.v-input.transparent input,
.v-input.transparent textarea {
  background-color: transparent !important;
  border: 0;
}

.global-loading .v-overlay__scrim {
  background-color: var(--black-color) !important;
}

/* carousel nav arrows */
.gen-style-2 .owl-carousel .owl-nav {
  top: 93%;
}

.gen-style-2 .owl-theme .owl-nav div[class*='owl-'],
.gen-style-2 .owl-theme .owl-nav div[class*='owl-']:hover {
  background-color: transparent;
  color: transparent;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  opacity: .5;
  cursor: pointer;
}

.gen-style-2 .owl-theme .owl-nav div[class*='owl-']:hover {
  opacity: 1;
}

.gen-style-2 .owl-theme .owl-nav div.owl-prev,
.gen-style-2 .owl-theme .owl-nav div.owl-prev:hover {
  background-image: url(./assets/images/icons/chevron-back-outline.png);
}

.gen-style-2 .owl-theme .owl-nav div.owl-next,
.gen-style-2 .owl-theme .owl-nav div.owl-next:hover {
  background-image: url(./assets/images/icons/chevron-forward-outline.png);
}

/* TV focus effect  */
*[data-focusable] {
  transition: all .3s;

  &.focused {
    outline: none !important;
  }
}
</style>

<script>
import utility from '@/mixins/utility'

import { mapGetters } from 'vuex'

import Growler from '@/components/UI/Growler'
import Loading from '@/components/UI/Loading'
import SpatialNavigation from '@/components/UI/SpatialNavigation'

//import SpatialNavigationHelper from '@/components/UI/SpatialNavigationHelper'
//import Stripe from '@/components/ecommerce/Stripe'

import SETTINGS from '@/plugins/settings'

export default {
  name: 'App',

  mixins: [utility],

  components: {
    Growler,
    Loading,
    SpatialNavigation,
    //SpatialNavigationHelper,
    //Stripe
  },

  data: () => ({
    //
  }),

  computed:
  {
    ...mapGetters({
      isLoading: 'ui/isLoading'
    })
  },
  mounted() {

    this.$addRemoteKeyListener("Back", this.handleBackButton);

  },

  beforeDestroy() {

    this.$deleteRemoteKeyListener("Back", this.handleBackButton)

  },
  methods:
  {
    handleBackButton() {

      this.$router.go(-1)

    }
  },
  watch:
  {
    $route() {
      //this.$vuetify.goTo(0)
    }
  },

  metaInfo() {
    let titleTemplate = `%s | ${SETTINGS.APP_NAME}`

    return {
      titleTemplate,
      meta: [
        { name: 'robots', content: 'index,follow' },
        { name: 'og:site_name', content: SETTINGS.APP_NAME },
      ]
    }
  }

};
</script>
