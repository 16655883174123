<template>
  <div class="pb-7" v-focus-section>

    <v-btn tile x-large
      v-if="show.trailer"
      class="mr-4"
      @click="goTo({ name:'trailer', params: { slug:show.slug } })"
      v-focus>
      TRAILER
    </v-btn>
                            
    <v-btn tile x-large
      color="primary" 
      @click="goToVideo"
      v-show="show.canPlay"
      class="mr-5"
      id="play-button"
      ref="playButton"
      v-focus>
      <v-icon class="mr-2">mdi-play</v-icon>
      RIPRODUCI
    </v-btn>

    <v-btn tile x-large
      v-show="!show.canPlay && !user"
      @click="goTo('login')"
      color="primary"
      class="mr-5"
      v-focus>
      ACCEDI
    </v-btn>

    <WatchlistButton :fab="true" 
                     :icon="false"
                     :show="show"
                     v-if="user"/>

    <v-alert type="info"
             v-if="showSubscriptionMessage"
             class="mt-10">
      Questo show non &egrave; compreso nel tuo abbonamento
    </v-alert>

  </div>
</template>

<style type="text/css" scoped>
   
</style>

<script>
  import { mapGetters } from 'vuex'

  import utility from '@/mixins/utility'

  import Show from '@/models/show'

  import WatchlistButton from '@/components/watchlist/Button'

  import ZWAN_STORE from '@/plugins/zwanStore'

  export default {
    name: 'ShowHeaderButtonRow',

    mixins: [utility],

    components: {
      WatchlistButton
    },

    data: () => ({
      isLoading: false,
    }),

    props: {
      show: {
        type: Show,
        default: null
      }
    },

    mounted(){



    },

    updated(){

      const navigation = this.$SpatialNavigation
      const playButton = this.$refs.playButton

      this.$nextTick(() => {
        if(!playButton) return
        navigation.focus(playButton.$el)

      })
      
    },

    computed: {
      
      ...mapGetters({
        'user': 'user'
      }),

      showSubscriptionMessage()
      {
        return !this.show.isPublic &&
               this.user && 
               !this.user.checkPlans(this.show.plans)
      },

      videoHash()
      {
        return this.show.videoHash
      }

    },

    methods: {

      goToVideo()
      {
        let videoHash = this.videoHash

        this.goTo({ name:'video', params: { videoHash } })
      },

      subscribe()
      {
        this.isLoading = true

        ZWAN_STORE.payload()
                  .then(({ data })=>{
                    let state = data.payload,
                        url = ZWAN_STORE.signup({ state })

                    window.open(url)

                    this.isLoading = false
                  })
      }
    }
  }
</script>
