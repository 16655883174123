<template>
  <v-navigation-drawer
      v-model="status"
      absolute
      temporary
      right
      class="mobile-menu"
      color="#182031"
    >

      <v-row>
      
        <v-col cols="12" 
               class="text-center my-10 py-10">
          <Logo white/>
        </v-col>

        <v-col cols="12">
          
          <v-list
            nav
            dense
          >
            <v-list-item-group>
              
              <div v-for="(item, i) in menu"
                   :key="i">
                
                <v-list-item 
                  @click="goTo(item.route)">
                  <v-list-item-icon>
                    <v-icon :class="`${item.color}--text`">
                      mdi-{{ item.icon }}
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-title :class="`${item.color}--text`">
                    {{ item.label }}
                  </v-list-item-title>
                </v-list-item>

                <v-divider class="my-5"
                           v-if="(i+1) < menu.length"/>

              </div>

            </v-list-item-group>
          </v-list>

        </v-col>
      
      </v-row>

    </v-navigation-drawer>
</template>

<style type="text/css" scoped>
   .mobile-menu {
    z-index: 999999999999;
   }
</style>

<script>
  import { mapGetters } from 'vuex'

  import utility from '@/mixins/utility'

  import Logo from '@/components/UI/Logo'

  import goTo from 'vuetify/lib/services/goto'

  export default {
    name: 'MobileMenu',

    mixins: [utility],

    components: 
    {
      Logo
    },

    data: () => ({
      status: false
    }),

    props: {
      
    },

    computed:
    {
      ...mapGetters([
        'isLogged',
      ]),

      menu()
      {
        let menu = [
          { icon: 'home', label: 'HOME', route: 'home' },
          { icon: 'magnify', label: 'CERCA', route: 'search' },
          { icon: 'television', label: 'GUARDA ORA', route: 'watch' },
        ]

        if( this.isLogged )
          menu = menu.concat([
              { icon: 'account', label: 'IL TUO ACCOUNT', route: 'account' },
              { icon: 'power', label: 'ESCI DA BUSINESS+', route: 'logout', color: 'red' },
            ])

        else
          menu.push({
            icon: 'login-variant',
            label: 'ACCEDI',
            route: 'login'
          })

        return menu
      }
    },

    methods: {
      toggle()
      {
        this.status = !this.status
      }
    },

    watch:
    {
      status(status)
      {
        if(status)
          goTo(0)
      }
    }
  }
</script>
