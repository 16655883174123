<template>
  <v-btn href="mailto:info@zwan.it" 
         text 
         small 
         color="primary"
         class="px-1">
    info@zwan.it
  </v-btn>
</template>

<style lang="scss" scoped>
   
</style>

<script>
  export default {
    name: 'MailTo',

    mixins: [],

    components: {
      
    },

    data: () => ({
      
    }),

    props: {
      
    },

    computed: {
      
    }
  }
</script>
