import utility from '@/mixins/utility'

class Plan {

  #data = {}

  #prices = []

  constructor(collection={})
  {  
    this.#data = collection

    this.#prices = utility.methods.mapModels(collection.prices, 'price')
  }

  /**
   * Property getters.
   */
  
  get id()
  {
    return this.#data.id
  }

  get active()
  {
    return this.#data.active
  }

  get description()
  {
    return this.#data.description
  }  

  get name()
  {
    return this.#data.name
  }

  get prices()
  {
    return this.#prices
  }

  /**
   * Computed properties.
   */
  
  get isActive()
  {
    return this.active ? true : false
  }

}

export default Plan