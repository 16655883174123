import _ from 'lodash'
import moment from 'moment'

import utility from '@/mixins/utility'

import store from '@/store'

class Show {

  #data = {}

  #plans = []

  #series = []

  #serie = null

  constructor(show={})
  {  
    this.#data = show

    if(show.serie)
      this.#serie = utility.methods.mapModel(show.serie, 'serie')

    this.#series = _.map(show.series, (serie)=>{

      serie.episodes = utility.methods.mapModels(serie.episodes)

      return serie
    })

    this.#plans = utility.methods.mapModels(show.plans, 'plan')
  }

  /**
   * Property getters.
   */
  
  get bg()
  {
    return this.#data.bg || null
  }

  get bgVideo()
  {
    return this.#data.bg_video
  }

  get cast()
  {
    return this.#data.cast || []
  }

  get cover()
  {
    return this.#data.cover || null
  }

  get description()
  {
    return this.#data.description
  }

  get duration()
  {
    return this.#data.duration
  }

  get excerpt()
  {
    return this.#data.excerpt
  }

  get genres()
  {
    return this.#data.genres || []
  }

  get id()
  {
    return this.#data.id
  }

  get shareUrl()
  {
    return this.#data.share_url
  }

  /**
   * Check if show has publishing date and this date is passed.
   */
  get isPublished()
  {
    let now = moment()

    return this.publishedAt ? this.publishedAt.isBefore(now) : true
  }

  /**
   * Check if show has publishing date and this date is yet to come.
   */
  get isPublishing()
  {
    let now = moment()

    return this.publishedAt ? this.publishedAt.isAfter(now) : false
  }

  get logo()
  {
    return this.#data.logo || null
  }

  get plans()
  {
    return this.#plans
  }

  get poster()
  {
    return this.#data.poster || null
  }

  get publishedAt()
  {
    let date,
        episode = this.#firstEpisodeWithVideo

    if( this.#data.published_at )
      date = this.#data.published_at

    else if( episode && episode.rawPublishedAt )
      date = episode.rawPublishedAt

    return date ? moment(date) : null
  }

  get rawPublishedAt()
  {
    return this.#data.published_at ? moment(this.#data.published_at) : null
  }

  get serie()
  {
    return this.#serie
  }

  get series()
  {
    return this.#series || []
  }

  get slideshow()
  {
    return this.#data.slideshow || null
  }

  get slug()
  {
    return this.#data.slug
  }

  get tagline()
  {
    return this.#data.tagline
  }

  get tags()
  {
    return this.#data.tags || []
  }

  get title()
  {
    return this.#data.title
  }

  get trailer()
  {
    return this.#data.trailer
  }

  get video()
  {
    if(this.#data.video)
      return this.#data.video

    let episode = this.#firstEpisodeWithVideo

    if(episode)
      return episode.video

    return null
  }

  get videoHash()
  {
    if(this.#data.video_hash)
      return this.#data.video_hash

    let episode = this.#firstEpisodeWithVideo

    if(episode)
      return episode.videoHash

    return null
  }

  get year()
  {
    return this.#data.year
  }

  /**
   * Computed properties.
   */
  
  get canPlay()
  {
    if( this.#data.video )
      return this.rawCanPlay

    let episode = this.#firstEpisodeWithVideo

    if( episode )
      return episode.canPlay

    return false
  }
  
  get rawCanPlay()
  {
    let user = store.getters.user

    // se non ho impostato un video non posso neanche riprodurlo!
    if( !this.#data.video )
      return false

    // se lo show è pubblico lo riproduco sempre
    if( this.isPublic )
      return true

    // controllo se lo show ha una data di pubblicazione e se è passata
    if( !this.isPublished )
      return false

    // se non ho un utente loggato non riproduco il video (a meno che non sia pubblico nel controllo precedente)
    if( !user )
      return false

    // se l'utente è loggato controllo se ha un abbonamento attivo
    if( !user.checkPlans(this.plans) )
      return false

    return true
  }
  
  get episodes()
  {
    var episodes = []

    this.series.forEach((serie)=>{
      episodes = episodes.concat(serie.episodes)
    })

    return episodes
  }

  get hasCast()
  {
    return this.cast.length > 0
  }

  get hasDetails()
  {
    return (this.hasCast ||
            this.hasGenres ||
            this.hasTags)
  }

  get hasGenres()
  {
    return this.genres.length > 0
  }

  get hasEpisodes()
  {
    return this.episodes.length > 0
  }

  get hasPlans()
  {
    return this.plans.length > 0
  }

  get hasSeries()
  {
    return this.series.length > 0
  }

  get hasTags()
  {
    return this.tags.length > 0
  }

  get isEpisode()
  {
    return typeof this.#data.serie != 'undefined'
  }

  get isPublic()
  {
    return this.#data.public
  }

  /**
   * Helpers.
   */
  
  get #firstEpisodeWithVideo()
  {
    var episodesWithVideo = _.filter(this.episodes, (episode)=>episode.video)

    return episodesWithVideo.length ? episodesWithVideo[0] : null
  }

}

export default Show