<template>
  <v-container>

    <!-- <h3 class="mt-5">Dettagli</h3> -->

    <ul class="show-meta mt-5" v-focus-section>
      <!-- Cast -->
      <li v-if="show.hasCast" v-focus-section>
        <strong class="primary--text mr-5">Cast : </strong>
        <span v-for="(cast,i) in show.cast" :key="i">
          <v-btn text v-focus 
             @click="goTo({ name:'cast', params:{slug: cast.slug} })">
            {{ cast.name }}  
          </v-btn>
          <span v-if="(i+1) < show.cast.length">, </span>
        </span>
      </li>
      <!-- Genere -->
      <li v-if="show.hasGenres" v-focus-section>
        <strong class="primary--text mr-5">Genere : </strong>
        <span v-for="(genre,i) in show.genres" :key="i">
          <v-btn text v-focus 
             @click="goTo({ name:'genre', params:{slug: genre.slug} })">
            {{ genre.name }}  
          </v-btn>
          <span v-if="(i+1) < show.genres.length">, </span>
        </span>
      </li>
      <!-- Tags -->
      <li v-if="show.hasTags" v-focus-section>
        <strong class="primary--text mr-5">Tags : </strong>
        <span v-for="(tag,i) in show.tags" :key="i">
          <v-btn text v-focus 
             @click="goTo({ name:'tag', params:{slug: tag.slug} })">
            {{ tag.name }}
          </v-btn>
          <span v-if="(i+1) < show.tags.length">, </span>
        </span>
      </li>
    </ul>
  </v-container>
</template>

<style lang="scss" scoped>  
  .show-meta {
    list-style: none;
    font-family: var(--title-fonts);
    font-size: 18px;
    li {
      margin-bottom: 15px;
    }
  }
</style>

<script>
  import utility from '@/mixins/utility'

  import Show from '@/models/show'

  export default {
    name: 'ShowTabDetails',

    mixins: [utility],

    components: {
    },

    data: () => ({
      
    }),

    props: {
      show: {
        type: Show,
        default: null
      }
    },

    computed: {
      
    },

    methods: {

    }
  }
</script>
