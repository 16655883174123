import EventBus from "@/plugins/events"
import { mapMutations } from 'vuex'

const networkMixin = {

	data: () => ({
		status: 'online'
	}),

	methods: {
		...mapMutations({
			setLoading: 'ui/setLoading'
		}),
		handleOnlineStatus(e, status) {
			this.status = status
		},
		redirect(val){
			if (val === 'offline' && this.$route.name != 'connection-error') {
				const path = this.$route.path
				this.$router.push({
					name: "connection-error",
					params: { to: path, status: val }
				})
			}
		}
	},

	mounted() {

		window.addEventListener("online", (e) => this.handleOnlineStatus(e, "online"))
		window.addEventListener("offline", (e) => this.handleOnlineStatus(e, "offline"))

		
		EventBus.$on("network-error", () => {
			this.handleOnlineStatus(null, "offline")
			this.setLoading(false)
		})

	},

	beforeDestroy() {

		window.removeEventListener("online", (e) => this.handleOnlineStatus(e, "online"))
		window.removeEventListener("offline", (e) => this.handleOnlineStatus(e, "offline"))

	},


	watch: {
		status(val) {
			this.redirect(val)
		}
	},

}

export default networkMixin