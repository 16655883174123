import utility from '@/mixins/utility'

class Tag {

  #data = {}

  #shows = []

  constructor(collection={})
  {  
    this.#data = collection

    this.#shows = utility.methods.mapModels(collection.shows)
  }

  /**
   * Property getters.
   */
  
  get id()
  {
    return this.#data.id
  }

  get slug()
  {
    return this.#data.slug
  }

  get name()
  {
    return this.#data.name
  }

  get shows()
  {
    return this.#shows
  }

  /**
   * Computed properties.
   */
  

}

export default Tag