import Api from './'

const shows = {

	/**
	 * Get a list of shows.
	 */
	list(params={})
	{
		let url = Api.getUrl(`shows`)

		return Api.client().get(url, { params })
	},

	/**
	 * Get one show details.
	 */
	one(showSlug, params={})
	{
		let url = Api.getUrl(`show/${showSlug}`)

		return Api.client().get(url, { params })
	},

	/**
	 * Get latest published shows.
	 */
	latest(params={})
	{
		let url = Api.getUrl(`shows/latest`)

		return Api.client().get(url, { params })
	},

	/**
	 * Get one show from its video hash.
	 */
	fromVideo(videoHash, params={})
	{
		let url = Api.getUrl(`show/video/${videoHash}`)

		return Api.client().get(url, { params })
	},

}

export default shows