<template>
  <v-navigation-drawer permanent :expand-on-hover="!forceSidebar" app color="#182031" v-focus-section id="entrypoint">

    <div class="text-center">
      <Logo white class="only-on-mouseover mt-20 mb-10" />
    </div>

    <v-divider></v-divider>

    <!-- MENU -->
    <v-list nav class="pt-5" v-focus-section>
      <!--
      <SidebarLink @click="goTo('keytest')" icon="home" divider>
        KEYS
      </SidebarLink>
      -->


      <SidebarLink @click="goTo('home')" icon="home" divider>
        HOME
      </SidebarLink>

      <SidebarLink @click="goTo('search')" icon="magnify" divider>
        CERCA
      </SidebarLink>

      <SidebarLink @click="goTo('watch')" icon="television" divider>
        GUARDA ORA
      </SidebarLink>

      <!-- logged out -->

      <SidebarLink @click="goTo('login')" icon="account" icon-color="primary" item-class="text-uppercase" v-if="!isLogged"
        title-class="primary--text">
        ACCEDI
      </SidebarLink>


      <!-- logged in -->

      <SidebarLink @click="goTo('watchlist')" icon="plus" v-if="isLogged" divider>
        LA MIA LISTA
      </SidebarLink>


      <!--
      <SidebarLink @click="goTo('account')" 
                   icon="account"
                   v-if="isLogged"
                   divider>
        IL TUO ACCOUNT
      </SidebarLink>
      -->

      <SidebarLink @click="logout" icon="power" icon-color="red" item-class="text-uppercase" title-class="red--text"
        v-if="isLogged">
        ESCI DA {{ appName }}
      </SidebarLink>

    </v-list>

  </v-navigation-drawer>
</template>

<style lang="scss" scoped>
.v-navigation-drawer--mini-variant {

  & .only-on-mouseover {
    -webkit-transition: opacity .3s;
    transition: opacity .3s;
  }

  &.v-navigation-drawer--is-mouseover {
    & .only-on-mouseover {
      opacity: 1;
    }
  }

  &:not(.v-navigation-drawer--is-mouseover) {
    & .only-on-mouseover {
      opacity: 0;
    }
  }

}
</style>

<script>
/**
 * @see https://vuetifyjs.com/en/components/navigation-drawers/#permanent-and-floating
 */

import utility from '@/mixins/utility'

import { mapGetters } from 'vuex'

import Logo from '@/components/UI/Logo'
import SidebarLink from '@/components/UI/SidebarLink'

export default {
  name: 'Sidebar',

  mixins: [utility],

  components: {
    SidebarLink,
    Logo
  },

  data: () => ({}),

  computed: {
    ...mapGetters({
      forceSidebar: 'ui/forceSidebar',
      isLogged: 'isLogged',
      user: 'user'
    }),

    logoSettings() {
      let isHome = (this.$route.name === 'home')

      if (!isHome)
        return {
          height: 50,
          class: ''
        }

      return {
        height: 100,
        class: 'mt-20'
      }
    }
  },

  methods:
  {
    logout() {
      this.$store.dispatch('logout')
    }
  }
}
</script>
