<template>
  <div>

    <v-list-item 
      link
      @click="onClick"
      :class="listItemCss"
      v-focus>
      <v-list-item-icon>
        <v-icon :color="iconColor">mdi-{{ icon }}</v-icon>
      </v-list-item-icon>
      <v-list-item-title :class="titleClass">
        <slot></slot>
      </v-list-item-title>
    </v-list-item>
    
    <v-divider class="my-5" v-if="divider"/>

  </div>
</template>

<style type="text/css" scoped>
   
</style>

<script>
  export default {
    name: 'SidebarLink',

    mixins: [],

    components: {},

    data: () => ({
      
    }),

    props: {
      itemClass: {
        type: String,
        default: ''
      },
      divider: {
        type: Boolean,
        default: false
      },
      icon: {
        type: String,
        default: ''
      },
      iconColor: {
        type: String,
        default: ''
      },
      titleClass: {
        type: String,
        default: ''
      }
    },

    computed: 
    {
      listItemCss()
      {
        return `sidebar-link ${this.itemClass}`
      }
    },

    methods: {
      onClick()
      {
        this.$emit('click')
      }
    }
  }
</script>
