<template>
  <div class="gen-single-meta-holder">
      <ul>
          <li v-if="show.hasSeries">
            {{ show.series.length }} stagioni
          </li>
          <li v-if="show.hasEpisodes">
            {{ show.episodes.length }} episodi
          </li>
          <li v-if="show.year">
            {{ show.year }}
          </li>
          <!-- Generi -->
          <li v-if="show.hasGenres">
              <span v-for="(genre,i) in show.genres" :key="i">
                <a href="#"> {{ genre.name }}</a>
                <span v-if="(i+1) < show.genres.length" class="mr-2">,</span>
              </span>
          </li>
          <!--
          <li>
              <i class="fas fa-eye">
              </i>
              <span>5.5K Views</span>
          </li>
          -->
      </ul>
  </div>
</template>

<style lang="scss" scoped>  
  
</style>

<script>
  import Show from '@/models/show'

  export default {
    name: 'ShowHeaderInfoRow',

    mixins: [],

    components: {
    },

    data: () => ({
      
    }),

    props: {
      show: {
        type: Show,
        default: null
      }
    },

    computed: {
      
    },

    methods: {

    }
  }
</script>
