import Api from './'

const auth = {

	/**
	 * Fetch data for logged in user.
	 */
	refresh(params={})
	{
		let url = Api.getUrl(`user`)

		return Api.client().get(url, { params })
	},

	updateUser(data = {})
	{
		let url = Api.getUrl('user')

		return Api.client().post(url, {...data})
	},

	updatePassword(data = {})
	{
		let url = Api.getUrl('user/password')

		return Api.client().post(url, {...data})
	},

	deleteSubscription(subscriptionName)
	{
		let url = Api.getUrl('subscription'),
			data = { subscriptionName }

		return Api.client().delete(url, { data })
	},

	resumeSubscription(subscriptionName)
	{
		let url = Api.getUrl('subscription/resume')

		return Api.client().post(url, { subscriptionName })
	}

}

export default auth