<template>
  <v-container v-focus-section>

    <h1 class="mt-7">{{ cast.name }}</h1>

    <v-row class="mt-7">
      <v-col v-for="(show,i) in shows" 
             :key="i"
             cols="3">
        <ShowCard :show="show"/>
      </v-col>
    </v-row>

    <v-alert type="info" v-if="!hasShows">
      Non sono disponibili video con questo protagonista.
    </v-alert>

  </v-container>
</template>

<script>
import API from '@/api'

import slugMixin from '@/mixins/slug'
import utility from '@/mixins/utility'

import ShowCard from '@/components/show/Card'

export default {
  
  name: 'Cast',

  mixins: [ slugMixin, utility ],

  components: {
    ShowCard
  },

  data: () => ({
     cast: {}
  }),

  computed: {
    hasShows()
    {
      return this.shows && this.shows.length
    },
    shows()
    {
      return this.cast.shows
    }
  },

  methods:
  {
    load()
    {
      API.casts.one(this.slug)
               .then((res)=>{
                this.cast = this.mapModel(res.data.data, 'cast')
               })
    }
  },

  mounted()
  {
    this.load()
  }

}
</script>
