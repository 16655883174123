import Api from './'

const genres = {

	/**
	 * Get a list of casts.
	 */
	list(params={})
	{
		let url = Api.getUrl(`genres`)

		return Api.client().get(url, { params })
	},

	/**
	 * Get one cast details.
	 */
	one(slug, params={})
	{
		let url = Api.getUrl(`genre/${slug}`)

		return Api.client().get(url, { params })
	},

}

export default genres