<template>
  <SeriesTab :series="series"/>
</template>

<style lang="scss" scoped>  
  
</style>

<script>
  import Show from '@/models/show'

  import SeriesTab from '@/components/slideshows/SeriesTab'

  export default {
    name: 'ShowTabEpisodes',

    mixins: [],

    components: {
      SeriesTab
    },

    data: () => ({
      
    }),

    props: {
      show: {
        type: Show,
        default: null
      }
    },

    computed:
    {
      series()
      {
        return this.show.series
      }  
    },

    methods: {

    }
  }
</script>
